import React, { useEffect, useState } from "react";
import useCurrentPage from "../../../hooks/useCurrentPage";
import { Formik } from "formik";
import { Label, Table } from "reactstrap";
import Select from "react-select";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import moment from "moment";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/statusChange.svg";
import { Button } from "react-bootstrap";
import IconButton from "../../../assets/elements/IconButton";

import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import MainServices from "../../../services/MainServices";
import Cross from "../../../assets/images/icons/cross.svg";
import ItemsDroppable from "../../../assets/dragDrop/ItemsDroppable";
import EachDragItem from "../../../assets/dragDrop/EachDragItem";
import { squareBackIcon } from "../../../assets/images/icons/icons";
import { Modal } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

import { DragDropContext, Draggable } from "react-beautiful-dnd";
import StrictModeDroppable from "../../../assets/dnd/StrictModeDroppable";
import axios from "axios";
import * as Yup from "yup";
import PrintLoading from "../../../assets/loaders/PrintLoading";
import { PrimaryButton } from "../../../assets/buttons";
import { PrimaryInput } from "../../../assets/FormElements";
import { useLocation, useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../assets/elements/Toast";
import ArrowBack from "../../../assets/images/Galfar/vuesax/linear/arrow-left.svg";


const workflowSchema = Yup.object().shape({
  users: Yup.array().of(
    Yup.object().shape({
      dueDate: Yup.string().required("due date is required"),
      userId: Yup.string().required("user is required"),
    })
  ),
});
const dueDateSchema = Yup.object().shape({
  dueDate: Yup.string().required("due date is required"),
});

export default function DocumentWorkflow() {
  const [employees, setEmployees] = useState([]);
  const {
    getEmployeesMaster,
    getDepartmentsMaster,
    getIDCMasters,
    addDocumentWorkflow,
    getIdcDeptsandEmployees,
  } = MainServices();
  const [workflowModal, setWorkflowModal] = useState(false);
  const [discplines, setDisciplines] = useState([]);
  const [workflows, setWorkFlows] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const { updateCurrentPage } = useCurrentPage();
  const [dueDateModal, setDueDateModal] = useState(false);
  const [documentDueDateModal, setDocumentDueDateModal] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const location = useLocation();

  const documentId = new URLSearchParams(location.search).get("docId");
  const ctrId = new URLSearchParams(location.search).get("ctrId");
  const idc = new URLSearchParams(location.search).get("idc");
  const disciplineId = new URLSearchParams(location.search).get("disciplineId");
  const navigate = useNavigate();
  useEffect(() => {
    updateCurrentPage({
      title: "Documents",
      subtitle: "Add Workflow",
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getWorkflows({});
  }, []);

  useEffect(() => {
    if (ctrId && ctrId !== "null") {
      getEmployeesByCtr(ctrId);
    } else {
      if (disciplineId) {
        getEmployeesByDiscipline({
          label: disciplineId,
          publicId: disciplineId,
        });
      } else {
        getEmployeesByDiscipline();
      }
    }
    getDepartments();
  }, [ctrId]);

  //apis
  const getEmployeesByDiscipline = async (e) => {
    const details = {
      pageSize: 100,
      pageNumber: 1,
      ...(e && e.value !== "all"
        ? {
          filters: [{ key: "deptId", eq: e.publicId }],
        }
        : {}),
    };

    getEmployeesMaster(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data.map((each) => {
            return {
              deptartment: { deptName: each?.deptartment?.deptName },
              designation: { desgName: each?.designation?.desgName },
              label: each.empName,
              value: each.publicId,
            };
          });

          setEmployees(resData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEmployeesByCtr = async (ctrId) => {
    const details = {};
    await axios
      .all([getIdcDeptsandEmployees(details, ctrId)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            resData = resData?.data || [];
            let employees = [];
            resData = resData.map((each) => {
              let emps = each.employees || [];
              console.log(emps)
              emps = emps.map((emp) => {
                employees.push({
                  label: emp.empName,
                  value: emp.publicId,
                  deptartment: { deptName: emp?.deptartment?.deptName },
                  designation: { desgName: emp?.designation?.desgName },
                });
                return {
                  ...emp,
                  label: emp.empName,
                  value: emp.publicId,
                };
              });
              return {
                ...each,
                label: each.department?.deptName,
                value: each.department?.publicId,
                employees: emps,
              };
            });
            resData = resData.filter((eachD)=>eachD.department)
            resData.unshift({
              label: "All",
              value: "all",
              employees,
            });
            setDisciplines(resData);
            setEmployees(employees);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Depts");
      });
  };

  console.log(discplines, employees);

  const getDepartments = async () => {
    const details = {
      pageSize: 100,
      pageNumber: 1,
    };
    setPageLoading(true);
    await getDepartmentsMaster(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data;
          resData = resData.map((each) => {
            if (disciplineId && each.publicId === disciplineId) {
              setSelectedOption({
                ...each,
                label: each.deptName,
                value: each.publicId,
              });
            }
            return {
              ...each,
              label: each.deptName,
              value: each.publicId,
            };
          });
          resData.unshift({ label: "All", value: "all" });
          setDisciplines(resData);
          setPageLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };

  const getWorkflows = async ({ page, limit }) => {
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters: [{ key: "status", eq: "active" }],
      //   filters: [
      //     { key: "status", eq: "active" },
      //     ...(idc && idc === "No" ? [{ key: "idc", eq: idc }] : []),
      //   ],
    };
    await axios
      .all([getIDCMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let IDCMasters = resData.data ? resData.data : [];
            IDCMasters = IDCMasters.map((_, index) => {
              return {
                ..._,
                label: _.name,
                value: _.publicId,
              };
            });
            setWorkFlows(IDCMasters);
          } else {
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting IDC Matrix");
      });
  };
  const addWorkflow = async (values) => {
    setButtonLoading(true);
    let users = values.users || [];
    users = users.map((eachUser) => ({
      userId: eachUser.userId,
      dueDate: moment(eachUser.dueDate).format("DD-MM-YYYY"),
    }));
    const details = {
      documentId,
      users: users,
    };
    setPageLoading(true);
    await addDocumentWorkflow(details)
      .then((res) => {
        if (res.success) {
          let resData = res.data;
          notifySuccess("Workflow added successfully");
          navigate({
            pathname: "/documents/document-details",
            search: `?documentId=${documentId}`,
          });
          setButtonLoading(false);
        } else {
          setButtonLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setButtonLoading(false);
      });
  };
  //functions
  const onDragItem = ({ item, setFieldValue, workflow, values }) => {
    let workflows = values.workflows || [];
    workflows = workflows.map((eachW) => {
      if (eachW.workflow === workflow) {
        let users = eachW.users || [];
        let match = users.find((each) => each.value === item.value);
        if (!match) {
          users.push(item);
        }
        return {
          ...eachW,
          users,
        };
      }
      return eachW;
    });
    console.log(workflows);
    setFieldValue(workflows);
  };

  const onDragEnd = (result, set, values) => {
    const { source, destination, type } = result;

    // If dropped outside a droppable area
    if (!destination) {
      return;
    }

    // If the drag type is 'SECTION'

    const update_values = reorder(
      values.workflows,
      source.index,
      destination.index
    );
    set("workflows", update_values);

    // If the drag type is 'ITEM' within the same section
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onManageWorkflow = () => {
    setWorkflowModal((prev) => !prev);
  };
  const onDueDate = () => {
    setDueDateModal(!dueDateModal);
  };
  const onDocumentDueDate = () => {
    setDocumentDueDateModal(!documentDueDateModal);
  };

  return (
    <div className="add-document-workflow">
      <div className="d-flex">
        <div className="back-btn-function mt-0">
          <img src={ArrowBack} alt="" onClick={() => navigate(-1)} />
        </div>
        <div className="header">
          <p className="content-header-main">
            <span>Document Workflow</span>
          </p>
        </div>
      </div>


      <hr
        style={{
          background: "#BFBEC5",
          margin: "16px 0px 32px",
        }}
      />
      <div className="card bg-white p-4">
        <Formik
          initialValues={{
            workflows: [
              {
                users: [],
              },
            ],
            workflow: "",
            users: [],
            selected: {},
          }}
          validationSchema={workflowSchema}
          onSubmit={(values) => {
            addWorkflow(values);
          }}
        >
          {({
            setFieldValue,
            values,
            handleBlur,
            errors,
            touched,
            handleSubmit,
          }) => (
            <div className="worklow-users">
              {/* {console.log(values.users)} */}
              {/* <div className="row ">
                <div className="">
                  <Label>WorkFlow</Label>
                </div>
                <div className="w-50">
                  <Select
                    className="sop-custom-form-select"
                    id="workflow"
                    name="workflow"
                    placeholder="Select WorkFlow"
                    onChange={(e) => {
                      let users = e.data || [];
                      users = users.map((eachUser) => ({
                        ...eachUser,
                        userId: eachUser.value,
                      }));
                      setFieldValue("workflows", [{ users }]);
                      setFieldValue("workflow", e);
                      setFieldValue("users", users);
                    }}
                    value={values.workflow}
                    onBlur={handleBlur}
                    options={workflows}
                  />
                  <CustomErrorMessage
                    name="discipline"
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div> */}
              <div className="users-table mt-3">
                <div className="user-header d-flex justify-content-between">
                  <h5>Users</h5>
                  <div className="d-flex gap-1">
                    <IconButton
                      title="Due date"
                      onClick={onDocumentDueDate}
                      disabled={!values.users.length}
                    />
                    <IconButton
                      title="Add user"
                      iconType="add"
                      onClick={onManageWorkflow}
                    />

                  </div>
                </div>
                <div className="content">
                  <div className="table-main">
                    <Table responsive bordered className="table table-bordered">
                      <thead className="service-table-head">
                        <tr>
                          <th>#</th>
                          <th>Discipline </th>
                          <th>Employee Name</th>
                          <th>Due Date</th>
                          <th style={{width : "100px"}}>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.users.map((each, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{each?.discipline}</td>
                              <td>{each?.label}</td>
                              <td>
                                {each?.dueDate
                                  ? moment(each?.dueDate).format("DD-MM-YYYY")
                                  : ""}
                                <div
                                  onClick={() => {
                                    setFieldValue("selected", each);
                                    onDueDate();
                                  }}
                                  style={{ cursor: "pointer" }}
                                  data-tooltip-id="editTooltip"
                                  data-tooltip-content="Edit Document Workflow"
                                >
                                  <Tooltip id="editTooltip" place="top" effect="solid" />
                                  <img src={editicon} alt="Edit icon" />
                                </div>
                              </td>

                              <td className="options">
                                <div className="d-flex justify-content-around">
                                  <div
                                    onClick={() => {
                                      let users = values.users || [];
                                      users = users.filter(
                                        (eachU) => each.userId !== eachU.userId
                                      );
                                      setFieldValue("users", users);
                                      setFieldValue("workflows", [{ users }]);
                                    }}
                                    style={{ cursor: "pointer" }}
                                    data-tooltip-id="statusChangeTooltip"
                                    data-tooltip-content={each?.status === 'active' ? 'Inactivate Document Workflow' : 'Activate Document Workflow'}
                                  >
                                    <Tooltip id="statusChangeTooltip" place="top" effect="solid" />
                                    <img src={removeicon} alt="Remove icon" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    {errors && errors.users && errors.users.length > 0 && (
                      <span className="error">
                        Due date is required for every user
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <Modal
                centered
                show={dueDateModal}
                onHide={() => onDueDate()}
                className="add-department-modal-main"
                size="md"
              >
                <Modal.Header
                  closeButton
                  className="add-department-modal-header"
                >
                  <Modal.Title className="add-department-modal-title">
                    Update due date
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className="add-department-modal-body">
                  <Formik
                    initialValues={{
                      dueDate: values?.selected?.dueDate,
                    }}
                    onSubmit={(d_values) => {
                      let selectedId = values.selected.value;
                      let users = values.users || [];
                      users = users.map((eachUser) => {
                        if (eachUser.value === selectedId) {
                          return {
                            ...eachUser,
                            dueDate: d_values.dueDate,
                          };
                        } else return eachUser;
                      });
                      setFieldValue("users", users);
                      setFieldValue("workflows", [{ users }]);
                      setFieldValue("selected", {});
                      onDueDate();
                    }}
                    validationSchema={dueDateSchema}
                    key="due-date"
                  >
                    {({
                      handleSubmit: d_due_submit,
                      values: d_due_values,
                      handleChange: d_due_change,
                      handleBlur: d_due_blur,
                      errors: d_due_errors,
                      touched: d_due_touched,
                    }) => (
                      <>
                        <div className="row align-items-center form-group mb-3">
                          <div className="">
                            <Label>Due Date</Label>
                          </div>
                          <div className="">
                            <PrimaryInput
                              type="date"
                              placeholder="Enter date"
                              onChange={d_due_change}
                              onBlur={d_due_blur}
                              value={d_due_values.dueDate}
                              name="dueDate"
                              id="dueDate"
                              min={moment(new Date()).format("YYYY-MM-DD")}
                            />
                            <CustomErrorMessage
                              name="dueDate"
                              errors={d_due_errors}
                              touched={d_due_touched}
                            />
                          </div>
                        </div>
                        <div className="mt-3">
                          <div className="d-flex gap-2">
                            <Button
                              className="back-icon-btn-main"
                              onClick={onDocumentDueDate}
                            >
                              <span>{squareBackIcon}</span>
                              <span className="ms-2">Back</span>
                            </Button>
                            <Button
                              className="theme-btn-main"
                              onClick={d_due_submit}
                            >
                              {false ? <ButtonLoading /> : "Submit"}
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
              <Modal
                centered
                show={documentDueDateModal}
                onHide={() => onDocumentDueDate()}
                className="add-department-modal-main"
                size="md"
              >
                <Modal.Header
                  closeButton
                  className="add-department-modal-header"
                >
                  <Modal.Title className="add-department-modal-title">
                    Update Document due date
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className="add-department-modal-body">
                  <Formik
                    initialValues={{
                      dueDate: "",
                    }}
                    onSubmit={(d_values) => {
                     
                      let users = values.users || [];
                      users = users.map((eachUser) => {
                       
                          return {
                            ...eachUser,
                            dueDate: d_values.dueDate,
                          };
                       
                      });
                      setFieldValue("users", users);
                      setFieldValue("workflows", [{ users }]);
                      onDocumentDueDate();
                    }}
                    validationSchema={dueDateSchema}
                    key="due-date"
                  >
                    {({
                      handleSubmit: due_submit,
                      values: due_values,
                      handleChange: due_change,
                      handleBlur: due_blur,
                      errors: due_errors,
                      touched: due_touched,
                    }) => (
                      <>
                        <div className="row align-items-center form-group mb-3">
                          <div className="">
                            <Label>Due Date</Label>
                          </div>
                          <div className="">
                            <PrimaryInput
                              type="date"
                              placeholder="Enter date"
                              onChange={due_change}
                              onBlur={due_blur}
                              value={due_values.dueDate}
                              name="dueDate"
                              id="dueDate"
                              min={moment(new Date()).format("YYYY-MM-DD")}
                            />
                            <CustomErrorMessage
                              name="dueDate"
                              errors={due_errors}
                              touched={due_touched}
                            />
                          </div>
                        </div>
                        <div className="mt-3">
                          <div className="d-flex gap-2">
                            <Button
                              className="back-icon-btn-main"
                              onClick={onDueDate}
                            >
                              <span>{squareBackIcon}</span>
                              <span className="ms-2">Back</span>
                            </Button>
                            <Button
                              className="theme-btn-main"
                              onClick={due_submit}
                            >
                              {false ? <ButtonLoading /> : "Submit"}
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
              <Modal
                centered
                show={workflowModal}
                onHide={() => onManageWorkflow()}
                className="add-department-modal-main"
                size="xl"
              >
                <Modal.Header
                  closeButton
                  className="add-department-modal-header"
                >
                  <Modal.Title className="add-department-modal-title">
                    Manage Workflow 
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className="add-department-modal-body">
                  <Formik
                    initialValues={{
                      workflows: values.workflows,
                      workflow: "",
                    }}
                    onSubmit={(m_values) => {
                      onManageWorkflow();
                      let users = [];
                      users = values.workflows[0].users;
                      console.log("hhh", users);
                      users = users.map((eachU) => {
                        return {
                          discipline: eachU?.deptartment?.deptName,
                          userId: eachU.value,
                          ...eachU,
                        };
                      });
                      setFieldValue("users", users);
                      setFieldValue("workflows", [{ users }]);
                    }}
                    key=""
                  >
                    {({
                      handleSubmit: m_submit,
                      setFieldValue: m_set,
                      values: m_values,
                      handleChange: m_change,
                      handleBlur: m_blur,
                    }) => (
                      <>
                        <div className="addidcmatrix-main">
                          <div className="addidcmatrix-discpline col-12">
                            <div className="row align-items-center form-group mt-3 mb-3">
                              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                <Label>Discipline</Label>
                              </div>
                              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                <Select
                                  className="sop-custom-form-select"
                                  id="deptartment"
                                  name="deptartment"
                                  placeholder="---Select Discipline---"
                                  onChange={(e) => {
                                    if (ctrId && ctrId !== "null") {
                                      let emps = e.employees || [];
                                      setEmployees(emps);
                                    } else {
                                      console.log("here");
                                      getEmployeesByDiscipline(e);
                                    }
                                    setSelectedOption(e);
                                  }}
                                  value={selectedOption}
                                  options={discplines}
                                  isDisabled={idc === "No"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex gap-2" style={{ width: "100%" }}>
                          <div
                            style={{
                              width: "49%",
                              height: 500,
                              overflowY: "scroll",
                            }}
                          >
                            <h6 className="mt-3" style={{ fontWeight: "700" }}>
                              WorkFlows
                            </h6>
                            <div className="d-flex gap-2 mb-3">
                              <DragDropContext
                                onDragEnd={(result) =>
                                  onDragEnd(result, m_set, m_values)
                                }
                              >
                                <StrictModeDroppable
                                  droppableId="workflows"
                                  direction="vertical"
                                >
                                  {(sectionsProvided) => (
                                    <div
                                      style={{
                                        width: "100%",
                                      }}
                                      {...sectionsProvided.droppableProps}
                                      ref={sectionsProvided.innerRef}
                                    >
                                      {m_values.workflows.map(
                                        (section, sectionIndex) => (
                                          <Draggable
                                            key={section.workflow}
                                            draggableId={section.workflow}
                                            index={sectionIndex}
                                            style={{ width: "100%" }}
                                          >
                                            {(sectionProvided) => (
                                              <div
                                                {...sectionProvided.draggableProps}
                                                {...sectionProvided.dragHandleProps}
                                                ref={sectionProvided.innerRef}
                                              >
                                                <ItemsDroppable
                                                  onDrag={(props) =>
                                                    onDragItem({
                                                      ...props,
                                                      workflow:
                                                        section.workflow,
                                                      values: m_values,
                                                    })
                                                  }
                                                  setFieldValue={m_set}
                                                  dataType="Available"
                                                >
                                                  <div className="each-section my-3">
                                                    <div className="d-flex justify-content-between">
                                                      <h6
                                                        style={{
                                                          textTransform:
                                                            "capitalize",
                                                          fontWeight: "600",
                                                        }}
                                                      >
                                                        Drag and Drop Employees
                                                        Here
                                                      </h6>
                                                    </div>
                                                    <div className="users mt-2 px-3">
                                                      {section.users &&
                                                        section.users.length >
                                                        0 &&
                                                        section.users.map(
                                                          (user, subI) => (
                                                            <div className="each-user d-flex justify-content-between mt-2">
                                                              <div>
                                                                <span>
                                                                  {subI + 1}.
                                                                </span>
                                                                <span
                                                                  style={{
                                                                    textTransform:
                                                                      "capitalize",
                                                                  }}
                                                                >
                                                                  {user.label}
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <img
                                                                  alt="Cross"
                                                                  src={Cross}
                                                                  className="delete-topic"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() => {
                                                                    let data =
                                                                      m_values?.workflows ||
                                                                      [];
                                                                    let users =
                                                                      data[
                                                                        sectionIndex
                                                                      ].users ||
                                                                      [];
                                                                    users =
                                                                      users.filter(
                                                                        (
                                                                          each,
                                                                          i
                                                                        ) =>
                                                                          subI !==
                                                                          i
                                                                      );
                                                                    data[
                                                                      sectionIndex
                                                                    ].users =
                                                                      users;
                                                                    m_set(
                                                                      "workflows",
                                                                      data
                                                                    );
                                                                  }}
                                                                />
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                    </div>
                                                  </div>
                                                </ItemsDroppable>
                                              </div>
                                            )}
                                          </Draggable>
                                        )
                                      )}
                                      {sectionsProvided.placeholder}
                                    </div>
                                  )}
                                </StrictModeDroppable>
                              </DragDropContext>
                            </div>
                          </div>
                          <div className="available" style={{ width: "49%" }}>
                            <ItemsDroppable
                              onDrag={onDragItem}
                              setFieldValue={m_set}
                              dataType="Available"
                              style={{ height: 500 }}
                            >
                              {console.log(employees)}
                              <h5>Employees</h5>
                              {employees &&
                                employees.length > 0 &&
                                employees.map((_, index) => (
                                  <EachDragItem props={_} type="availble" />
                                ))}
                            </ItemsDroppable>
                          </div>
                        </div>

                        <div className="mt-3">
                          <div className="d-flex gap-2">
                            <Button
                              className="back-icon-btn-main"
                              onClick={() => {
                                onManageWorkflow();
                              }}
                            >
                              <span>{squareBackIcon}</span>
                              <span className="ms-2">Back</span>
                            </Button>
                            <Button
                              className="theme-btn-main"
                              onClick={m_submit}
                            >
                              {false ? <ButtonLoading /> : "Submit"}
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>

              <div className="d-flex justify-content-end gap-2 ">
                <div>
                  <Button onClick={() => navigate(-1)}>Back</Button>
                </div>
                <div>
                  <PrimaryButton
                    onClick={handleSubmit}
                    title={buttonLoading ? <ButtonLoading /> : "Submit"}
                  />
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
