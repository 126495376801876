import React, { useEffect, useState } from "react";
import './pendingReview.scss'
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBack from "../../../assets/images/Galfar/vuesax/linear/arrow-left.svg";
import MainServices from "../../../services/MainServices";
import axios from "axios";
import { Table } from "reactstrap";
import Pagination from "../../../assets/elements/CustomPagination";
import useCurrentPage from "../../../hooks/useCurrentPage";
import {
    paramsToFilters,
    paramsToFiltersObj,
    filtersToUri,
    filtersToUrlParams,
} from "../../../assets/functions/filters";
import { InputGroup, Form, Modal } from "react-bootstrap";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import AsyncSelect from "react-select/async";

export default function PendingReview() {
    const { getTotalReviews, getProjectMasters } = MainServices()
    const navigate = useNavigate()
    const location = useLocation()
    const [pendingReviews, setPendingReviews] = useState()
    const [totalPendingReviews, setTotalPendingReviews] = useState(0)
    const [pagination, setPagination] = useState({ page: 1, limit: 10 })
    const [projectOptions, setProjectOptions] = useState([]);
    const [projectId, setProjectId] = useState("");
    const [filters, setFilters] = useState([])
    const { updateCurrentPage } = useCurrentPage();
    const params = new URLSearchParams(location.search);
    const changedParams = Object.fromEntries(params);
    const {deBounce}=TimingFunctions()
    useEffect(() => {
        updateCurrentPage({
            title: "Pending Reviews",

        });
    }, [])

    useEffect(() => {
        let filters = paramsToFilters(changedParams?.filters, "filters");
        let filtersObj = paramsToFiltersObj(changedParams?.filters);

        setFilters(filtersObj);

        setPagination({
            page: changedParams?.page || 1,
            limit: changedParams?.limit || 10,
        });
        getTotalReviewsList({ page: changedParams?.page||1, limit: changedParams?.limit||10, filters });
        getProjectOptions();
    }, [changedParams?.page,
    changedParams?.limit,
    changedParams?.filters,
    changedParams?.sorting])

    useEffect(() => {
        getTotalReviewsList({
            page: changedParams?.page || 1,
            limit: changedParams.limit || 50
        });
    }, [projectId]);

    const getTotalReviewsList = async ({ page, limit, filters }) => {
        console.log('page', page)
        const details = {
            pageSize: limit || 10,
            pageNumber: page || 1,
            filters
        };
        if (projectId && projectId !== "") details.projectId = projectId;
        await axios
            .all([getTotalReviews(details)])
            .then(res => {
                if (res[0]) {
                    let resData = res[0].data ? res[0].data : [];
                    let totalCount = res[0].totalCount ? res[0].totalCount : 0;
                    resData = resData?.map((each, index) => {
                        return {
                            ...each,
                            sNo: index + 1 + (page - 1) * limit
                        }
                    })
                    console.log('pending reviews', resData)
                    setPendingReviews(resData);
                    setTotalPendingReviews(totalCount)
                }
            })
    }

    const getProjectOptions = async (inputValue) => {
        const details = {
          pageSize: 50,
          pageNumber: 1,
          ...(inputValue
            ? {
                filters: [
                  { key: "name", iLike: inputValue },
                  { key: "status", eq: "active" },
                ],
              }
            : { filters: [{ key: "status", eq: "active" }] }),
          sorting: [{ key: "created_at", direction: "desc" }],
        };
        let options = await axios
          .all([getProjectMasters(details)])
          .then(function (res) {
            if (res[0]) {
              let resData = res[0];
              if (resData.success) {
                let projects = resData.data ? resData.data : [];
                projects = projects.map((_, index) => {
                  return {
                    ..._,
                    label: _.name,
                    value: _.publicId,
                  };
                });
                setProjectOptions(projects);
                return projects;
              }
            }
          })
          .catch(function (res) {
            if (res.message) console.log("An error occurred in getting Projects");
            return [];
          });
        return options;
      };
    
      const loadDebounceOptions = (inputValue, callback) => {
        deBounce(() => loadOptions(inputValue, callback), 1000);
      };
    
      const loadOptions = async (inputValue, callback) => {
        if (!inputValue) {
          callback(projectOptions);
        }
    
        let options = [];
    
        options = await getProjectOptions(inputValue);
    
        callback(options);
      };

    const onPage = (page) => {
        let mfilters = filtersToUri(filters);
        let search = filtersToUrlParams({
            page,
            limit: pagination.limit,
            filters: mfilters,

        });
        navigate({ search });
    };

    const searchPendingReviews = (e) => {
        deBounce(() => onSearchFilter(e), 1000);
    
      };
    
      const onSearchFilter = (e) => {
        let mfilters = filtersToUri({
          ...filters,
          name: { name: e.target.value },
        });
        let search = filtersToUrlParams({
          page: 1,
          limit: 10,
          filters: mfilters,
        });
        navigate({
          search,
        });
      };
    return (
        <div className="pendingReview-main">
            <div className="heading">
                <div>
                <span><img src={ArrowBack} onClick={() => navigate(-1)} /></span>
                <span style={{ fontWeight: '700', fontSize: '20px' }}>
                    {' '}Pending Reviews ({`${totalPendingReviews}`})</span>
                    </div>
                <InputGroup style={{ width: "auto" }}>
                    <Form.Control
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="search"
                        className="search-input-main"
                        onChange={(e) => searchPendingReviews(e)}
                    />
                    <InputGroup.Text id="search" className="search-input-main">
                        <img src={searchicon} alt="search icon" />
                    </InputGroup.Text>
                </InputGroup>
            </div>
            <div className="d-flex justify-content-end my-3">
                <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                    <AsyncSelect
                        className="sop-custom-form-select"
                        id="ProjectId"
                        placeholder="Select Project "
                        onChange={(e) => setProjectId(e?.publicId)}
                        loadOptions={(input, callback) =>
                            loadDebounceOptions(input, callback)
                        }
                        defaultOptions={projectOptions}
                        cacheOptions
                    />
                </div>
            </div>
            <div className="pending-table-main mb-3">
                {pendingReviews && pendingReviews.length>0 ?
                    <Table bordered responsive className="bordered table-bordered">
                        <thead>
                            <tr>
                                <th>S.No.</th>
                                <th>Document Number</th>
                                <th>Document Name</th>
                                <th>Project Name</th>
                                <th>Initiated By</th>
                                <th>Document Status</th>
                               

                            </tr>
                        </thead>
                        <tbody>
                            {pendingReviews && pendingReviews.length > 0 &&
                                pendingReviews?.map(each => (
                                    <tr>
                                        <td>{each?.sNo}</td>
                                        <td
                                            style={{ cursor: 'pointer', color: '#4682B4' }}
                                            onClick={() => {
                                                navigate({
                                                    pathname: '/documents/document-details',
                                                    search: `?documentId=${each?.document?.publicId}`
                                                })
                                            }}>{each?.document?.documentNumber}</td>
                                        <td>{each?.document?.documentName}</td>
                                        <td>{each?.document?.project?.name}</td>
                                        <td>{each?.author?.empEmail}</td>
                                        <td>{each?.document?.documentStatus}</td>
                                      
                                    </tr>
                                )

                                )}
                        </tbody>
                    </Table>
                    :<div>No Pending Reviews Found</div>
                }

            </div>
            {pendingReviews &&
                <div class="custom-pagination-main">
                    <div class="card-inner">
                        <Pagination
                            totalCount={totalPendingReviews}
                            currentPage={pagination.page}
                            rowsPerPage={pagination.limit}
                            onNext={onPage}
                            onPrev={onPage}
                            onPageClick={onPage}

                        />
                    </div>
                </div>
            }
        </div>
    )
}