import { Oval, TailSpin } from "react-loader-spinner";
import "./Loaders.scss";

export const LazyLoader = () => {
  return (
    <div className="loader-main">
      <Oval
        height={80}
        width={40}
        color="#D60000"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#4fa94d"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};

export const ButtonLoader = ({ color }) => {
  return (
    <TailSpin
      width={25}
      height="1.5rem"
      color={color ? color : "white"}
      radius="2"
      wrapperClass="loader-button"
      visible={true}
    />
  );
};
