import React, { useState, useEffect, useRef } from "react";
import "./PLIPList.scss";
import { InputGroup, Form, Modal } from "react-bootstrap";
import { Label, Button } from "reactstrap";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import IconButton from "../../../assets/elements/IconButton";
import { useAsyncError, useLocation, useNavigate } from "react-router-dom";
import PageLoader from "../../../assets/loaders/PageLoading";
import AsyncSelect from "react-select/async";
import { Table } from "reactstrap";
import moment from "moment";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/statusChange.svg";
import Pagination from "../../../assets/elements/CustomPagination";
import useCurrentPage from "../../../hooks/useCurrentPage";
import axios from "axios";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import { Formik } from "formik";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { PrimaryInput } from "../../../assets/FormElements";
import Select from "react-select";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import * as Yup from "yup";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import useAuth from "../../../hooks/useAuth";
import { squareBackIcon } from "../../../assets/images/icons/icons";
import { PrimaryButton } from "../../../assets/buttons";
import { ButtonLoader, LazyLoader } from "../../../assets/loaders";
import export1 from "../../../assets/images/icons/export1.svg";
import { Tooltip } from "react-tooltip";

import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import MainServices from "../../../services/MainServices";
import { toast } from "react-toastify";
import { ConstBookmark } from "devexpress-richedit/lib/core/model/bookmarks";
import useConfig from "../../../hooks/useConfig";

export default function PLIPList() {
  const { grantPermission } = PermissionFunctions();
  const [pageLoading, setPageLoading] = useState(false);
  const [PLIP, setPLIP] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { updateCurrentPage } = useCurrentPage();
  const [filters, setFilters] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
  });
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [searchString, setSearchString] = useState("");
  const [totalPLIP, setTotalPLIP] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [createPLIPModal, setCreatePLIPModal] = useState(false);
  const [selectedPLIP, setSelectedPLIP] = useState();
  const {
    deletePLIP,
    updatePLIP,
    importPLIP,
    getPLIP,
    getQueueStatus,
    exportPlip,
  } = MainServices();
  const { deBounce } = TimingFunctions();
  const [deletePLIPModal, setDeletePLIPModal] = useState(false);
  const [importPLIPModal, setImportPLIPModal] = useState(false);
  const { user } = useAuth();
  const [importLoading, setImportLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  let plipTimeOutRef = useRef(null);

  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);

  const plipImportingSchema = Yup.object().shape({
    fileName: Yup.string().required("Select file to be import"),
  });

  const { sampleFilesPath, exportsUrl } = useConfig();

  const onImportPLIP = () => {
    setImportPLIPModal(!importPLIPModal);
  };

  useEffect(() => {
    return () => {
      if (plipTimeOutRef.current) {
        clearTimeout(plipTimeOutRef.current);
      }
    };
  }, []);

  const getUploadStatus = async (jobId) => {
    const details = {};
    await axios
      .all([getQueueStatus(details, jobId)])
      .then((res) => {
        console.log("Upload Status", res);
        let status = res[0]?.data?.import_status;
        if (status === "completed") {
          setImportLoading(false);
          clearTimeout(plipTimeOutRef.current);
          getPLIPByStatus({
            page: changedParams?.page || 1,
            limit: changedParams.limit || 50,
          });
          toast.success("PLIP imported successfully");
        } else {
          plipTimeOutRef.current = setTimeout(() => {
            getPLIPByStatus({
              page: changedParams?.page || 1,
              limit: changedParams.limit || 50,
            });
            getUploadStatus(jobId);
          }, 2000);
        }
      })
      .catch((err) => {
        setImportLoading(false);
        console.log(err);
      });
  };

  const uploadFile = async (values) => {
    setButtonLoading(true);
    const details = {};
    const documents = values.fileName;

    await axios
      .all([importPLIP(documents, details, user?.token)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          console.log("importPlip", resData);
          onImportPLIP();
          setButtonLoading(false);
          let jobId = res[0]?.data?.data?.jobId;
          if (jobId) {
            setImportLoading(true);
            plipTimeOutRef.current = setTimeout(() => {
              getUploadStatus(jobId);
            }, 1000);
          }
          getPLIPByStatus({
            page: changedParams?.page || 1,
            limit: changedParams.limit || 50,
          });

          toast.success("PLIP imported successfully");
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in file upload");
      });
  };

  const allStatus = [
    { label: "All", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  useEffect(() => {
    updateCurrentPage({
      title: "Master",
      subtitle: "PLIP",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    setSelectedStatus(filtersObj?.status?.status || "");
    setFilters(filtersObj);
    setSearchString(changedParams?.searchString);
    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 50,
    });
   

    getPLIPByStatus({
      page: changedParams?.page || 1,
      limit: changedParams.limit || 50,
      filters,
      searchString: changedParams?.searchString || "",
    });
    // eslint-disable-next-line
  }, [
    changedParams.page,
    changedParams.limit,
    changedParams.filters,
    changedParams.searchString,
  ]);

  const getPLIPByStatus = async ({ page, limit, filters, searchString,initial }) => {
    let data=[]
    if (filters && Array.isArray(filters)) {
      filters = [...filters, { key: "status", eq: "active" }];
    } else {
      filters = [{ key: "status", eq: "active" }];
    }
    setPageLoading(true);
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters,
      searchString,
      sorting: [{ key: "created_at", direction: "asc" }],
    };
    await axios
      .all([getPLIP(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let PLIPData = resData.data ? resData.data : [];
            let total = resData?.totalCount || 0;
            setTotalPLIP(total);
            PLIPData = PLIPData.map((_, index) => {
              return {
                ..._,
                sNo: limit * (page - 1) + index + 1,
                label: _.plipId,
                value: _.plipId
              };
            });
            setPLIP(PLIPData);
            setPageLoading(false);
          data=PLIPData  
          } else {
            setButtonLoading(false);
          }
        } else {
          setPageLoading(false);
        }

      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Projects");
      });
      return data;
  };

  const downloadPlip = async () => {
    setExportLoading(true);
    const details = {};
    await axios
      .all([exportPlip(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let data = resData.data?.file || {};
            const { fileName } = data;
            if (fileName) {
              downloadFile(exportsUrl + "plip/" + fileName);
            } else {
              console.error("File path not found in response.");
            }
            setExportLoading(false);
          } else {
            setExportLoading(false);
          }
        } else {
          setExportLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Projects");
      });
  };

  const onCreatePLIP = (selected) => {
    if (selected) {
      setSelectedPLIP(selected);
    } else {
      setSelectedPLIP("");
    }
    setCreatePLIPModal(!createPLIPModal);
  };

  const onDeletePLIP = (selected) => {
    if (selected) {
      setSelectedPLIP(selected);
    } else {
      setSelectedPLIP("");
    }
    setDeletePLIPModal(!deletePLIPModal);
  };

  const changePLIPStatus = async () => {
    setButtonLoading(true);
    const status = selectedPLIP?.status==='active' ? 'inactive' : 'active'
    const details = {
      status
    };

    await axios
      .all([
        deletePLIP(
          details,
          selectedPLIP.publicId,
          selectedPLIP.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getPLIPByStatus({
              page: pagination.page,
              limit: pagination.limit,
              filters,
              searchString,
            });
            onDeletePLIP();
            notifySuccess("PLIP Status Updated Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in deleting PLIP");
      });
  };

  const searchPLIP = (inputValue, callback) => {
    deBounce(() => loadOptionsForPLIP(inputValue, callback), 1000);
  };

  // const searchPLIP = (e) => {

  //   deBounce(() => onSearchFilter(e), 1000);

  //   setSearchString(e);
  // };
  const loadOptionsForPLIP= async (inputValue, callback) => {
    if (!inputValue) {
      callback(PLIP);
      return;
    }
   
    let options = await getPLIPByStatus({ page:1,limit:10,filters,searchString:inputValue,initial:false });
    console.log('options',options)

    callback(options);
  };

  // const loadOptionsForPLIP = (e) => {
  //   let mfilters = filtersToUri({
  //     ...filters,
  //     //   code: { code: e.target.value },
  //   });
  //   let search = filtersToUrlParams({
  //     page: 1,
  //     limit: 50,
  //     filters: mfilters,
  //     searchString: e,
  //   });
  //   navigate({
  //     search,
  //   });
  // };

  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      searchString,
    });
    navigate({
      search,
    });
  };

  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
      searchString,
    });
    navigate({ search });
  };

  const onRowsPerPage = (rows) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
      searchString,
    });
    navigate({ search });
  };

  const downloadFile = (filePath) => {
    const link = document.createElement("a");
    link.href = filePath;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };

  const customStylesForPLIPSelect = {
    control: (provided) => ({
      ...provided,
      border: "1px solid black",
      borderRadius: "8px",
      boxShadow: "none",
      backgroundColor: "#ffffff",
      //padding: "6px 5px",
      marginBottom: "0",
      paddingLeft: "5px",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f5f2ed" : "white",
      color: "#000000",
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingInline: "5px",
    }),
  };

  return (
    <div className="plip-list-main">
      <div className="plip-list-header-section">
        <div className="plip-list-heading">
          PLIP List {`(${totalPLIP || 0})`}
        </div>
        <div className="d-flex gap-3">
          {importLoading && <ButtonLoader color="black" />}
          {/* <InputGroup style={{ width: "auto" }}>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="search"
              className="search-input-main"
              onChange={(e) => searchPLIP(e)}
            />
            <InputGroup.Text id="search" className="search-input-main">
              <img src={searchicon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup> */}
          <div style={{ minWidth: '200px' }}>
            <AsyncSelect
              styles={customStylesForPLIPSelect}
              className="select-role async-select"
            
              loadOptions={searchPLIP}
              defaultOptions={PLIP}
              // onChange={(e) => {
              //    searchPLIP(e)
              // }}

              cacheOptions
            />

          </div>

          <div className="product-list-header-flex" style={{ width: "8rem" }}>
            <select
              class="form-control form-select custom-status-select"
              id="fv-topics"
              name="status"
              data-placeholder="Select a option"
              required
              onChange={onStatusChange}
              value={selectedStatus}
            >
              <option label="Select Status" value=""></option>
              {allStatus.map((each) => (
                <option value={each.value} key={each.value}>
                  {each.label}
                </option>
              ))}
            </select>
          </div>

          {grantPermission("PLIP_read") && (
            <div
              // iconType="add"
              title="Export File"
              onClick={downloadPlip}
              className="folder-pic"
            >
              {exportLoading ? (
                <ButtonLoader />
              ) : (
                <img
                  src={export1}
                  alt=""
                  style={{ width: "30px", maxWidth: "30px", height: "25px" }}
                />
              )}
            </div>
          )}

          {grantPermission("PLIP_write") && (
            <IconButton
              iconType="add"
              title="Import PLIP"
              onClick={() => onImportPLIP()}
            />
          )}
        </div>
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : PLIP && PLIP.length > 0 ? (
        <div className="content mt-3">
          <div className="table-main">
            <Table responsive bordered className="table table-bordered">
              <thead className="service-table-head">
                <tr>
                  <th>#</th>
                  <th style={{ width: "8%" }}>PLIP Id</th>
                  <th>Discipline Code</th>
                  <th>Information</th>
                  <th>Type</th>
                  <th>Description</th>

                  {(grantPermission("PLIP_delete") ||
                    grantPermission("PLIP_update")) && <th  style={{width : "100px"}}>Actions</th>}
                </tr>
              </thead>
              <tbody>
                {PLIP.map((each) => {
                  return (
                    <tr>
                      <td>{each?.sNo}</td>
                      <td>{each?.plipId}</td>
                      <td>{each?.disciplineCode}</td>
                      <td>{each?.informationRequired}</td>
                      <td>{each?.documentType}</td>
                      <td>{each?.documentTypeDescription}</td>

                      {(grantPermission("PLIP_delete") ||
                        grantPermission("PLIP_update")) && (
                          <td className="options">
                            <div className="d-flex justify-content-around">
                              {grantPermission("PLIP_update") &&
                                each?.status === "active" && (
                                  <div
                                    onClick={() => onCreatePLIP(each)}
                                    style={{ cursor: "pointer" }}
                                    data-tooltip-id="editTooltip"
                                    data-tooltip-content="Edit PLIP List"
                                  >
                                    <Tooltip id="editTooltip" place="top" effect="solid" />
                                    <img src={editicon} alt="Edit icon" />
                                  </div>
                                )}
                              {grantPermission("PLIP_delete") && (
                                <div
                                  onClick={() => onDeletePLIP(each)}
                                  style={{ cursor: "pointer" }}
                                  data-tooltip-id="statusChangeTooltip"
                                  data-tooltip-content={each?.status==='active'?'Inactivate PLIP List':'Activate PLIP List'}
                                >
                                  <Tooltip id="statusChangeTooltip" place="top" effect="solid" />
                                  <img src={removeicon} alt="Remove icon" />
                                </div>
                              )}
                            </div>
                          </td>
                        )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="no-data-found-main">No PLIP Found</div>
      )}
      {PLIP && PLIP.length > 0 && (
        <div class="custom-pagination-main">
          <div class="card-inner">
            <Pagination
              totalCount={totalPLIP}
              currentPage={pagination.page}
              rowsPerPage={pagination.limit}
              onNext={onPage}
              onPrev={onPage}
              onPageClick={onPage}
              onRowsPerPage={onRowsPerPage}
            />
          </div>
        </div>
      )}
      <Modal
        centered
        show={importPLIPModal}
        onHide={onImportPLIP}
        className="add-department-modal-main"
        size="lg"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Import PLIP
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <Formik
            initialValues={{
              fileName: "",
            }}
            validationSchema={plipImportingSchema}
            onSubmit={(values) => uploadFile(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setErrors,
              setTouched,
              setFieldValue,
            }) => (
              <div>
                <div className="add-project-card-main mt-3">
                  <Form className="form" onSubmit={handleSubmit}>
                    <div className="row align-items-center form-group mb-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                        <Label>PLIP</Label>
                      </div>
                      <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                        <PrimaryInput
                          type="file"
                          onChange={(e) => {
                            setFieldValue("fileName", e?.target?.files[0]);
                          }}
                          onBlur={handleBlur}
                          accept=".xlsx,.xls"
                          name=" fileName"
                          id="fileName"
                        />
                        <CustomErrorMessage
                          name="fileName"
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                      <span
                        onClick={() =>
                          downloadFile(sampleFilesPath + "PLIP.xlsx")
                        }
                        style={{
                          color: "blue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Download Sample File
                      </span>
                    </div>

                    <div className="mt-3">
                      <div className="row">
                        <div className="col-6">
                          <Button
                            className="back-icon-btn-main"
                            onClick={onImportPLIP}
                            style={{ width: "100%" }}
                          >
                            <span>{squareBackIcon}</span>
                            <span className="ms-2">Back</span>
                          </Button>
                        </div>
                        <div className="col-6">
                          <PrimaryButton
                            className="theme-btn-main"
                            type="submit"
                            title={buttonLoading ? <ButtonLoading /> : "Submit"}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={deletePLIPModal}
        onHide={onDeletePLIP}
        className="add-department-modal-main"
        selected={selectedPLIP}
        size="md"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
          {selectedPLIP?.status==='active'?'Inactivate':'Activate'} PLIP
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="add-department-modal-body">
          <p>
            Do you want to make this PLIP {" "}
            <span style={{ color: "#9d72ff", textDecoration: "underline" }}>
              {selectedPLIP?.plipId}
            </span>{" "}
            {selectedPLIP?.status === 'active' ? 'inactive' : 'active'} ?
          </p>

          <Formik
            initialValues={{
              email: user.email,
              password: "",
            }}

            onSubmit={(values) => {
              changePLIPStatus(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form className="form" onSubmit={handleSubmit}>
                <div className="mt-3">
                  <div className="d-flex gap-2">
                    <Button
                      className="back-icon-btn-main"
                      onClick={onDeletePLIP}
                    >
                      <span>{squareBackIcon}</span>
                      <span className="ms-2">Back</span>
                    </Button>
                    <Button className="theme-btn-main" type="submit">
                      {buttonLoading ? <ButtonLoading /> : `${selectedPLIP?.status === 'active' ? 'Inactivate' : 'Activate'}`}

                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
