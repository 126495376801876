import React, { useEffect, useRef, useState } from "react";
import "./documentList.scss";
import { useLocation, useNavigate } from "react-router-dom";
import MainServices from "../../../services/MainServices";
import axios from "axios";
import useCurrentPage from "../../../hooks/useCurrentPage";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/statusChange.svg";
import viewicon from "../../../assets/images/icons/viewicon.svg";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import { Table } from "reactstrap";
import IconButton from "../../../assets/elements/IconButton";
import Pagination from "../../../assets/elements/CustomPagination";
import SuccessModal from "../../../assets/elements/SuccessModal";
import moment from "moment";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import { ButtonLoader, LazyLoader } from "../../../assets/loaders";
import PageLoader from "../../../assets/loaders/PageLoading";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import { icons, squareBackIcon } from "../../../assets/images/icons/icons";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";

import { notifySuccess } from "../../../assets/elements/Toast";

import { InputGroup, Form, Button, Modal, Dropdown } from "react-bootstrap";
import PrintLoading from "../../../assets/loaders/PrintLoading";
import useConfig from "../../../hooks/useConfig";
import { downloadFileWithFileName } from "../../../assets/functions/Download";
import { Formik } from "formik";
import * as Yup from "yup";
import { PrimaryInput } from "../../../assets/FormElements";
import useAuth from "../../../hooks/useAuth";
import options from "../../../assets/images/icons/options.svg";
import ProjectsDropdown from "../projects/ProjectDropdown";
import blackUpArrow from "../../../assets/images/icons/blackUpArrow.svg";
import redDownArrow from "../../../assets/images/icons/redDownArrow.svg";

export default function DocumentDdrList() {
  const [pageLoading, setPageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const location = useLocation();
  const {
    getProjectDetails,
    downloadProgressReport,
    getDocuments,
    deleteDocumentMaster,
    downloadDocumentDdr,
    importDDR,
    downloadDocumentIdc,
    getQueueStatus,
    downloadDocumentIdcPdf,
  } = MainServices();
  const { updateCurrentPage } = useCurrentPage();
  const [documents, setDocuments] = useState();
  const [filters, setFilters] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
  });
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [searchString, setSearchString] = useState("");
  const [totalDocumentCount, setTotalDocumentCount] = useState(0);
  const [downloadLoading, setDownloadLoading] = useState({});
  const [idcLoading, setIdcLoading] = useState({ csv: {}, pdf: {} });
  const { user } = useAuth();
  const allStatus = [
    { label: "All", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];
  const { grantPermission } = PermissionFunctions();
  const navigate = useNavigate();
  const { deBounce } = TimingFunctions();

  const params = new URLSearchParams(location.search);
  const changedParams = Object.fromEntries(params);
  const projectIdd = new URLSearchParams(location.search).get("projectId");
  const [projectId, setProjectId] = useState(projectIdd);
  const [selectedDocument, setSelectedDocument] = useState();
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [onImportDDR, setOnImportDDR] = useState(false);
  const [originalStatus, setOriginalStatus] = useState("all");
  const { ddrPath, idcPath } = useConfig();
  const [importLoading, setImportLoading] = useState(false);
  const [project, setProject] = useState();
  const [sortActions, setSortActions] = useState({ key: "created_at", direction: "desc" });
  const [sortIcon, setSortIcon] = useState({
    documentNumber: blackUpArrow,
    documentName: blackUpArrow,
    createdAt: blackUpArrow,
  });

  let ddrTimeoutRef = useRef(null);

  useEffect(() => {
    return () => {
      if (ddrTimeoutRef.current) {
        clearTimeout(ddrTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    updateCurrentPage({
      title: "Document List",
      subtitle: "",
    });
    // eslint-disable-next-line
  }, []);
  const FileValidationSchema = Yup.object().shape({
    fileName: Yup.mixed().required("File is Required"),
  });

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    setSelectedStatus(filtersObj?.status?.status || "");
    setOriginalStatus(filtersObj?.originalStatus?.originalStatus || "all");
    setFilters(filtersObj);

    setSearchString(filtersObj?.name?.name);
    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 50,
    });

    getDocumentsByProjectId({
      page: changedParams?.page || 1,
      limit: changedParams.limit || 50,
      filters,
      sorting: sortActions,
    });
    // eslint-disable-next-line
  }, [
    changedParams.page,
    changedParams.limit,
    changedParams.filters,
    changedParams.projectId,
    sortActions,
  ]);

  useEffect(() => {
    getDocumentsByProjectId({
      page: pagination?.page,
      limit: pagination?.limit,
      filters,
      sorting: sortActions,
      searchString,
    });
  }, [searchString]);

  // useEffect(() => {
  //     if (projectId) {
  //         getDocumentsByProjectId({ page: 1, limit: 20, filters: [{ key: 'projectId', eq: projectId }] });
  //     }
  // }, [projectId])

  useEffect(() => {
    if (projectId) getProjectDetailsPage();

    // eslint-disable-next-line
  }, []);

  const getProjectDetailsPage = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getProjectDetails(details, projectId)])
      .then(function (res) {
        if (res) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : {};
            setProject(resData);

            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Projects details");
      });
  };

  const handleProgressReportDownloadClick = async () => {
    const details = {
      projectId,
    };

    await axios
      .all([downloadProgressReport(details)])
      .then((response) => {
        if (response.length > 0) {
          const data = response[0].data;
          const fileName = data.response["fileName"];
          console.log(fileName);
          if (fileName) {
            const path = `https://files.appsarizona.com/galfar-apis/server/exports/engineer/${fileName}`;
            console.log(path);
            downloadProgressFile(path);
          } else {
            console.error("File path not found in response.");
          }
        } else {
          console.error("File path not found in response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching file:", error);
      });
  };

  const downloadProgressFile = (filePath) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", "progress_report.xlsx"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };

  const getDocumentsByProjectId = async ({ page, limit, filters, sorting }) => {
    setPageLoading(true);
    if (filters && Array.isArray(filters)) {
      filters = [
        ...filters,
        ...(projectId ? [{ key: "projectId", eq: projectId }] : []),
        { key: "ddr", eq: true },
        { key: "status", eq: "active" },
      ];
    } else {
      filters = [
        ...(projectId ? [{ key: "projectId", eq: projectId }] : []),
        { key: "ddr", eq: true },
        { key: "status", eq: "active" },
      ];
    }

    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      ...(sorting && Object.keys(sorting).length && {sorting : [ sorting ]}),
      filters,
    };
    if (searchString && searchString !== "") details.searchString = searchString;
    await axios
      .all([getDocuments(details)])
      .then(function (res) {
        let resData = res[0].data ? res[0].data : [];
        let totalCount = +res[0]?.totalCount || 0;

        setTotalDocumentCount(totalCount);
        resData = resData.map((_, index) => {
          return {
            ..._,
            sNo: index + 1 + (page - 1) * limit,
          };
        });

        setDocuments(resData);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      projectId,
    });
    navigate({
      search,
    });
  };
  //pagination
  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
      projectId,
    });
    navigate({ search });
  };

  const onRowsPerPage = (rows) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
      projectId,
    });
    navigate({ search });
  };

  const searchDocuments = (e) => {
    // deBounce(() => onSearchFilter(e), 1000);
    deBounce(() => setSearchString(e.target.value), 500);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      documentName: { documentName: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      projectId,
    });
    navigate({
      search,
    });
  };

  const onOriginalStatus = (e) => {
    let mfilters;
    if (e.target.value === "pending") {
      mfilters = filtersToUri({
        ...filters,
        originalStatus: { originalStatus: e.target.value },
      });
    } else {
      const { originalStatus, ...rfiltesrs } = filters;
      mfilters = filtersToUri({
        ...rfiltesrs,
      });
    }

    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      projectId,
    });
    navigate({
      search,
    });
  };

  const downloadDdr = async (documentId) => {
    setDownloadLoading({ [documentId]: true });
    const details = {
      documentId,
    };
    await axios
      .all([downloadDocumentDdr(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let filedata = resData?.data?.response || {};
            if (filedata.fileName) {
              setTimeout(() => {
                downloadFileWithFileName(
                  ddrPath + filedata.fileName,
                  filedata.fileName
                );
                notifySuccess("Ddr downloaded Successfully");
                setDownloadLoading({});
              }, 2000);
            }
          } else {
            setDownloadLoading({});
          }
        } else {
          setDownloadLoading({});
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Document");
      });
  };

  const downloadIdc = async (documentId) => {
    setIdcLoading({ csv: { [documentId]: true }, pdf: {} });
    const details = {
      documentId,
    };
    await axios
      .all([downloadDocumentIdc(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let filedata = resData?.data?.response || {};
            if (filedata.fileName) {
              setTimeout(() => {
                downloadFileWithFileName(
                  idcPath + filedata.fileName,
                  filedata.fileName
                );
                notifySuccess("Idc downloaded Successfully");
                setIdcLoading({ csv: {}, pdf: {} });
              }, 2000);
            }
          } else {
            setIdcLoading({ csv: {}, pdf: {} });
          }
        } else {
          setIdcLoading({ csv: {}, pdf: {} });
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Document");
      });
  };
  const downloadIdcPDF = async (documentId) => {
    setIdcLoading({ pdf: { [documentId]: true }, csv: {} });
    const details = {
      documentId,
    };
    await axios
      .all([downloadDocumentIdcPdf(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let filedata = resData?.data?.response || {};
            if (filedata.fileName) {
              setTimeout(() => {
                downloadFileWithFileName(
                  idcPath + filedata.fileName,
                  filedata.fileName
                );
                notifySuccess("Idc downloaded Successfully");
                setIdcLoading({ csv: {}, pdf: {} });
              }, 2000);
            }
          } else {
            setIdcLoading({ csv: {}, pdf: {} });
          }
        } else {
          setIdcLoading({ csv: {}, pdf: {} });
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Document");
      });
  };

  const onDeleteDocument = (selected) => {
    if (selected) {
      setSelectedDocument(selected);
    } else {
      setSelectedDocument("");
    }
    setDeleteDocumentModal(!deleteDocumentModal);
  };

  const deleteDocument = async () => {
    setButtonLoading(true);
    const details = {
      status: "inactive",
    };
    await axios
      .all([
        deleteDocumentMaster(
          details,
          selectedDocument.publicId,
          selectedDocument.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getDocumentsByProjectId({
              page: pagination.page,
              limit: pagination.limit,
              filters,
            });
            onDeleteDocument();
            notifySuccess("Document Deleted Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in deleting Document");
      });
  };
  const onImportDDRModal = () => {
    setOnImportDDR(!onImportDDR);
  };

  const getUploadStatus = async (jobId) => {
    const details = {};
    await axios
      .all([getQueueStatus(details, jobId)])
      .then((res) => {
        console.log("queue res", res);
        const status = res[0].data.import_status;
        if (status === "completed") {
          clearTimeout(ddrTimeoutRef.current);
          getDocumentsByProjectId({
            page: 1,
            limit: 50,
            filters,
          });
          setImportLoading(false);
          toast.success("DDR imported successfully");
        } else {
          getDocumentsByProjectId({
            page: 1,
            limit: 50,
            filters,
          });
          ddrTimeoutRef.current = setTimeout(() => {
            getUploadStatus(jobId);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
        setImportLoading(false);
      });
  };

  const fileUploadDDR = async (values) => {
    setButtonLoading(true);
    const details = {
      projectId,
    };
    let document = values.fileName;

    setButtonLoading(true);
    await axios
      .all([importDDR(document, details, user.token)])
      .then((res) => {
        if (res[0]) {
          setButtonLoading(false);
          onImportDDRModal();
          let jobId = res[0]?.data?.data?.jobId;
          setImportLoading(true);
          ddrTimeoutRef.current = setTimeout(() => {
            getUploadStatus(jobId);
          }, 1000);
        } else {
          setButtonLoading(false);
          onImportDDRModal();
        }
      })
      .catch((err) => {
        setButtonLoading(false);
        console.log(err);
      });
  };
  return (
    <div className="Document-list-main">
      <div className="admin-popular-main">
        <div className="header">
          <div className="d-flex align-items-center gap-2">
            <p onClick={() => navigate(-1)} role="button">
              {icons.squareBackIcon}
            </p>
            <p className="content-header-main">{`DDR List(${totalDocumentCount})`}</p>
          </div>

          <div className="d-flex gap-3">
            {importLoading && <ButtonLoader color="black" />}
            <div className="product-list-header-flex" style={{ width: "8rem" }}>
              <select
                class="form-control form-select custom-status-select"
                id="fv-topics"
                name="status"
                required
                onChange={onOriginalStatus}
                value={originalStatus}
              >
                <option value="all" key="all">
                  All
                </option>
                <option value="pending" key="pending">
                  Pending
                </option>
              </select>
            </div>
            <InputGroup style={{ width: "auto" }}>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search"
                className="search-input-main"
                onChange={(e) => searchDocuments(e)}
              />
              <InputGroup.Text id="search" className="search-input-main">
                <img src={searchicon} alt="search icon" />
              </InputGroup.Text>
            </InputGroup>
            <div>
              {grantPermission("Document_write") && (
                <IconButton
                  iconType="add"
                  title="Import DDR"
                  onClick={onImportDDRModal}
                />
              )}
            </div>
            <ProjectsDropdown
              projectId={project?.publicId}
              projectType={project?.projectType}
              page="ddr"
            />
          </div>
        </div>
        {pageLoading ? (
          <PageLoader />
        ) : documents && documents.length > 0 ? (
          <div className="content">
            <div className="table-main">
              <Table responsive bordered className="table table-bordered">
                <thead className="service-table-head">
                  <tr>
                    <th>#</th>
                    <th>
                      <div className="d-flex justify-content-between align-items-center">
                        Document Number
                        <div
                          onClick={() => {
                            setSortActions((prev) => {
                              const isSameKey = prev.key === "documentNumber";
                              const newDirection = isSameKey && prev.direction === "asc" ? "desc" : "asc";

                              setSortIcon((prevIcon) => ({
                                ...prevIcon,
                                documentNumber: newDirection === "asc" ? redDownArrow : blackUpArrow,
                              }));

                              return { key: "documentNumber", direction: newDirection };
                            });
                          }}
                          className="d-flex justify-content-center align-items-center"
                          style={{ cursor: "pointer", }}
                          data-tooltip-id="sortTooltip"
                          data-tooltip-content={`Sort by Document Number (${sortActions.direction === "asc" ? "Desc" : "Asc"})`}
                        >
                          <Tooltip id="sortTooltip" place="top" effect="solid" />
                          <img src={sortIcon?.documentNumber} alt="Sort icon" />
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="d-flex justify-content-between align-items-center">
                        Document Name
                        <div
                          onClick={() => {
                            setSortActions((prev) => {
                              const isSameKey = prev.key === "documentName";
                              const newDirection = isSameKey && prev.direction === "asc" ? "desc" : "asc";

                              setSortIcon((prevIcon) => ({
                                ...prevIcon,
                                documentName: newDirection === "asc" ? redDownArrow : blackUpArrow,
                              }));

                              return { key: "documentName", direction: newDirection };
                            });
                          }}
                          className="d-flex justify-content-center align-items-center"
                          style={{ cursor: "pointer", }}
                          data-tooltip-id="sortTooltip"
                          data-tooltip-content={`Sort by Document Name (${sortActions.direction === "asc" ? "Desc" : "Asc"})`}
                        >
                          <Tooltip id="sortTooltip" place="top" effect="solid" />
                          <img src={sortIcon?.documentName} alt="Sort icon" />
                        </div>
                      </div>
                    </th>
                    <th>Deliverable Code</th>
                    <th>Plip Id</th>
                    <th>Created By</th>
                    <th style={{ width: "12%" }}>
                      <div className="d-flex justify-content-between align-items-center">
                        Created At
                        <div
                          onClick={() => {
                            setSortActions((prev) => {
                              const isSameKey = prev.key === "createdAt";
                              const newDirection = isSameKey && prev.direction === "asc" ? "desc" : "asc";

                              setSortIcon((prevIcon) => ({
                                ...prevIcon,
                                createdAt: newDirection === "asc" ? redDownArrow : blackUpArrow,
                              }));

                              return { key: "createdAt", direction: newDirection };
                            });
                          }}
                          className="d-flex justify-content-center align-items-center"
                          style={{ cursor: "pointer", }}
                          data-tooltip-id="sortTooltip"
                          data-tooltip-content={`Sort by Created At (${sortActions.direction === "asc" ? "Desc" : "Asc"})`}
                        >
                          <Tooltip id="sortTooltip" place="top" effect="solid" />
                          <img src={sortIcon?.createdAt} alt="Sort icon" />
                        </div>
                      </div>
                    </th>
                    <th>Status</th>
                    {(grantPermission("Document_delete") ||
                      grantPermission("Document_update")) && <th style={{width : "100px"}}>Actions</th>}
                    <th>Download</th>
                    <td>IDC</td>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((each) => {
                    return (
                      <tr>
                        <td>{each?.sNo}</td>

                        <td style={{ color: "#E65C4F" }}>
                          <span
                            onClick={() =>
                              each.originalStatus !== "pending" &&
                              navigate({
                                pathname: "/documents/document-details",
                                search: `?documentId=${each.publicId}`,
                              })
                            }
                            role="button"
                          >
                            {each?.documentNumber}
                          </span>
                        </td>
                        <td>{each?.documentName}</td>
                        <td>{each?.masterCtr?.code || "-"}</td>
                        <td>{each?.plip?.plipId || "-"}</td>
                        <td>{each?.author?.empName}</td>
                        <td>{moment(each?.createdAt).format("DD-MM-YYYY")}</td>
                        <td>{each?.originalStatus}</td>
                        {(grantPermission("Document_delete") ||
                          grantPermission("Document_update")) && (
                          <td className="options">
                            <div className="d-flex justify-content-around">
                              {grantPermission("Document_update") &&
                                each.originalStatus === "pending" &&
                                each?.status === "active" && (
                                  <div
                                    onClick={() =>
                                      navigate({
                                        pathname: "/documents/add-document",
                                        search: `documentId=${each?.publicId}&projectId=${projectId}`,
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                    data-tooltip-id="editTooltip"
                                    data-tooltip-content="Edit DDR List"
                                  >
                                    <Tooltip id="editTooltip" place="top" effect="solid" />
                                    <img src={editicon} alt="Edit icon" />
                                  </div>
                                )}
                              {grantPermission("Document_delete") && (
                                <div
                                  onClick={() => onDeleteDocument(each)}
                                  style={{ cursor: "pointer" }}
                                  data-tooltip-id="statusChangeTooltip"
                                  data-tooltip-content={each?.status === 'active' ? 'Inactivate DDR List' : 'Activate DDR List'}
                                >
                                  <Tooltip id="statusChangeTooltip" place="top" effect="solid" />
                                  <img src={removeicon} alt="Remove icon" />
                                </div>
                              )}
                            </div>
                          </td>
                        )}
                        <td>
                          {downloadLoading[each.publicId] ? (
                            <PrintLoading />
                          ) : (
                            <span
                              style={{ color: "red" }}
                              role="button"
                              onClick={() => downloadDdr(each.publicId)}
                            >
                              DDR
                            </span>
                          )}
                        </td>
                        {each.originalStatus !== "pending" &&
                          each.originalStatus !== "IFR" &&
                          each.originalStatus !== "draft" && (
                            <td>
                              {idcLoading["csv"][each.publicId] ? (
                                <PrintLoading />
                              ) : (
                                <span
                                  style={{ color: "red" }}
                                  role="button"
                                  onClick={() => downloadIdc(each.publicId)}
                                >
                                  CSV
                                </span>
                              )}
                              {"    "}
                              {idcLoading["pdf"][each.publicId] ? (
                                <PrintLoading />
                              ) : (
                                <span
                                  style={{ color: "red" }}
                                  role="button"
                                  onClick={() => downloadIdcPDF(each.publicId)}
                                >
                                  PDF
                                </span>
                              )}
                            </td>
                          )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          <div className="no-data-found-main">No Documents Found</div>
        )}
        {documents && documents.length > 0 && (
          <div class="custom-pagination-main">
            <div class="card-inner">
              <Pagination
                totalCount={totalDocumentCount}
                currentPage={pagination.page}
                rowsPerPage={pagination.limit}
                onNext={onPage}
                onPrev={onPage}
                onPageClick={onPage}
                onRowsPerPage={onRowsPerPage}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        centered
        show={deleteDocumentModal}
        onHide={onDeleteDocument}
        className="add-department-modal-main"
        selected={selectedDocument}
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Delete Document
          </Modal.Title>
        </Modal.Header>

        {deleteDocumentModal && (
          <Modal.Body className="add-department-modal-body">
            <p>
              Do you want to delete this Document{" "}
              <span style={{ color: "#9d72ff", textDecoration: "underline" }}>
                {selectedDocument.documentName}
              </span>
              ?
            </p>

            <div className="mt-3">
              <div className="d-flex gap-2">
                <Button
                  className="back-icon-btn-main"
                  onClick={onDeleteDocument}
                >
                  <span>{squareBackIcon}</span>
                  <span className="ms-2">Back</span>
                </Button>
                <Button className="theme-btn-main" onClick={deleteDocument}>
                  {buttonLoading ? <ButtonLoading /> : "Delete"}
                </Button>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
      <Modal
        show={onImportDDR}
        onHide={onImportDDRModal}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Import DDR
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              fileName: "",
            }}
            validationSchema={FileValidationSchema}
            onSubmit={(values) => {
              fileUploadDDR(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div>
                  <PrimaryInput
                    type="file"
                    name="fileName"
                    id="fileName"
                    onChange={(e) => {
                      setFieldValue("fileName", e.target.files[0]);
                    }}
                  />
                  <div className="d-flex gap-3 mt-3">
                    <Button onClick={onImportDDRModal}>Back</Button>
                    <Button type="submit" disabled={buttonLoading}>
                      {buttonLoading ? <ButtonLoading /> : "Submit"}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
