import FetchServices from "./FetchServices";
import apiService from "./ApiServices";
import useConfig from "../hooks/useConfig";
import axios from "axios";
import { useState } from "react";

export default function MainServices() {
  const { AxiosService } = FetchServices();
  const config = useConfig();

  const Services = {
    //files Functions
    fileUpload: function (file) {
      const api = config.api;
      let API = api + apiService.uploadFile;
      var bodyFormData = new FormData();
      let fieldNames = [];
      file &&
        Object.keys(file).map((key) => {
          fieldNames.push(file[key].field);
          bodyFormData.append("files", file[key]);
          return true;
        });
      if (fieldNames && fieldNames.length > 0) {
        bodyFormData.append("fileLabels", JSON.stringify(fieldNames));
      }
      return axios({
        method: "POST",
        url: API,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
        data: bodyFormData,
      })
        .then(function (response) {
          var resData = response.data;
          if (!resData) {
            const message = resData.msg;
            const errorRes = { status: false, msg: message };
            return errorRes;
          }
          const apiResponse = resData;
          return { status: true, data: apiResponse, success: true };
        })
        .catch(function (res) {
          console.log(res);
          console.log("An error occurred in File Upload");
        });
    },
    uploadCommentFiles: function (file, token) {
      let API = config.galfarapi + apiService.uploadCommentFiles;
      var bodyFormData = new FormData();
      file &&
        Object.keys(file).map((key) => {
          bodyFormData.append("filelocal", file[key]);
          return true;
        });
      return axios({
        method: "POST",
        url: API,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: token,
        },
        data: bodyFormData,
      })
        .then(function (response) {
          var resData = response.data;
          if (!resData) {
            const message = resData.msg;
            const errorRes = { status: false, msg: message };
            return errorRes;
          }
          const apiResponse = resData;
          return { status: true, data: apiResponse, success: true };
        })
        .catch(function (res) {
          console.log(res);
          console.log("An error occurred in Upload ctr");
        });
    },

    importProjectCTR: function (file, token) {
      let API = config.galfarapi + apiService.importProjectCTR;
      var bodyFormData = new FormData();
      file &&
        Object.keys(file).map((key) => {
          bodyFormData.append("filelocal", file[key]);
          return true;
        });

      return axios({
        method: "POST",
        url: API,
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          Authorization: token,
        },
        data: bodyFormData,
      })
        .then(function (response) {
          var resData = response.data;
          if (!resData) {
            const message = resData.msg;
            const errorRes = { status: false, msg: message };
            return errorRes;
          }
          const apiResponse = resData;
          return { status: true, data: apiResponse, success: true };
        })
        .catch(function (res) {
          console.log(res);
          console.log("An error occurred in Upload ctr");
        });
    },

    //Queue Status
    getQueueStatus: function (details, jobId) {
      let API = config.galfarapi + apiService.getQueueStatus + "/" + jobId;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //download progress report

    downloadProgressReport: function (details) {
      let API = config.galfarapi + apiService.progressReportDownload;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    documentStatsByProjectId: function (details, projectId) {
      let API =
        config.galfarapi +
        apiService.documentStatsByProjectId +
        "/" +
        projectId;
      let payload = { ...details };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //exportProjectCTR
    exportProjectCTR: function (details) {
      let API = config.galfarapi + apiService.exportProjectCTR;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    ddrGeneration: function (details, id) {
      let API = config.galfarapi + apiService.ddrGeneration + "/" + id;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //User functions
    userSignin: function (details) {
      let API = config.api + apiService.userSignin;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: false, type }).then(
        (res) => {
          return res;
        }
      );
    },
    userGoogleSignin: function () {
      let API = config.api + apiService.userGoogleSignin;
      window.open(API, "_self");
    },
    userFacebookSignin: function () {
      let API = config.api + apiService.userFacebookSignin;
      window.open(API, "_self");
    },
    getUserDetails: function (details, token) {
      let API = config.api + apiService.getUserDetails;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },
    getJobCirculars: function (details) {
      let API = config.galfarapi + apiService.getJobCircularByProjectId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    getRoles: function (details) {
      let API = config.api + apiService.getRoles;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getJobCircularByProjectId: function (details, projectId) {
      let API =
        config.galfarapi +
        apiService.getJobCircularByProjectId +
        "/" +
        projectId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getJobCircularByProjectId: function (details, projectId) {
      let API =
        config.galfarapi +
        apiService.getJobCircularByProjectId +
        "/" +
        projectId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addJobCircularByProjectId: function (details, projectId) {
      let API =
        config.galfarapi +
        apiService.addJobCircularByProjectId +
        "/" +
        projectId;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateJobCircularByProjectId: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      let API =
        config.galfarapi +
        apiService.addJobCircularByProjectId +
        "/" +
        publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    updateJobCircularStatus: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.updateJobCircularStatus + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getSeniorEmployees: function (details, deptId) {
      let API = config.api + apiService.getSeniorEmployees + "/" + deptId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getEngineers: function (details, deptId) {
      let API = config.api + apiService.getEngineers + "/" + deptId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getProjectEngineers: function (details) {
      let API = config.api + apiService.getProjectEngineers;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getEngineeringManagers: function (details) {
      let API = config.api + apiService.getEngineeringManagers;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getResourceCost: function (details) {
      let API = config.api + apiService.getResourceCost;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addResourceCost: function (details) {
      let API = config.api + apiService.addResourceCost;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateResourceCost: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.updateResourceCost + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getUserNotifications: function (details, publicId) {
      let API = config.api + apiService.getUserNotifications + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateNotificationReadStatus: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      let API =
        config.api + apiService.updateNotificationReadStatus + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    resetpassword: function (details, publicId, concurrencyStamp) {
      let API = config.api + apiService.resetpassword + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    //permission api
    getRolePermissionsByRoles: function (details, token) {
      console.log("details", details);
      let API = config.api + apiService.getRolePermissionsByRoles;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },
    getAllPermission: function (details, token) {
      let API = config.api + apiService.getAllPermission;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },

    getToolsAccess: function (details) {
      let API = config.api + apiService.getToolsAccess;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //Department Master

    getDepartmentsMaster: function (details, token) {
      let API = config.api + apiService.getDepartmentsMaster;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },
    //Department Master

    getEmployeesMaster: function (details, token) {
      let API = config.api + apiService.getEmployeesMaster;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type, token }).then(
        (res) => {
          return res;
        }
      );
    },
    //Project Summaries
    getProjectResourceSummaryForCharts: function (details, publicId) {
      let API =
        config.galfarapi +
        apiService.getProjectResourceSummaryForCharts +
        "/" +
        publicId;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getProjectCostSummaryForCharts: function (details, publicId) {
      let API =
        config.galfarapi +
        apiService.getProjectCostSummaryForCharts +
        "/" +
        publicId;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getProjectHourSummaryForCharts: function (details, publicId) {
      let API =
        config.galfarapi +
        apiService.getProjectHourSummaryForCharts +
        "/" +
        publicId;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getProjectHourSummary: function (details, publicId) {
      let API =
        config.galfarapi + apiService.getProjectHourSummary + "/" + publicId;
      console.log("api", API);
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getProjectResourceSummary: function (details, publicId) {
      let API =
        config.galfarapi +
        apiService.getProjectResourceSummary +
        "/" +
        publicId;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getProjectCostSummary: function (details, publicId) {
      let API =
        config.galfarapi + apiService.getProjectCostSummary + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //CTR Activity
    getCTRActivityMasters: function (details) {
      let API = config.galfarapi + apiService.getCTRActivityMasters;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addCTRActivityMaster: function (details) {
      let API = config.galfarapi + apiService.addCTRActivityMaster;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateCTRActivityMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.updateCTRActivityMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    deleteCTRActivityMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.deleteCTRActivityMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getRevisionCTRActivity: function (details, publicId) {
      let API =
        config.galfarapi + apiService.getRevisionCTRActivity + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    //Master CTD List
    getMasterCTD: function (details) {
      let API = config.galfarapi + apiService.getMasterCTD;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addMasterCTD: function (details) {
      let API = config.galfarapi + apiService.addMasterCTD;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateMasterCTD: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.updateMasterCTD + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteMasterCTD: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.deleteMasterCTD + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getRevisionCTD: function (details, publicId) {
      let API = config.galfarapi + apiService.getRevisionCTD + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    //TPS List
    getTPSMaster: function (details) {
      let API = config.galfarapi + apiService.getTPSMaster;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addTPSMaster: function (details) {
      let API = config.galfarapi + apiService.addTPSMaster;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateTPSMaster: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.updateTPSMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    deleteTPSMaster: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.deleteTPSMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getRevisionTPS: function (details, publicId) {
      let API = config.galfarapi + apiService.getRevisionTPS + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    importDDR: function (file, details, token) {
      try {
        const api = config.galfarapi;
        let API = api + apiService.importDDR;

        var bodyFormData = new FormData();
        file && bodyFormData.append("ddr", file);
        details &&
          Object.keys(details).map((eachkey) => {
            bodyFormData.append(eachkey, details[eachkey]);
            return true;
          });

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log(res);
            console.log("An error occurred in File Upload");
          });
      } catch (error) {
        console.log(error);
      }
    },
    importMasterCTD: function (file, token) {
      try {
        const api = config.galfarapi;
        let API = api + apiService.importMasterCTD;

        var bodyFormData = new FormData();
        file && bodyFormData.append("master_ctd", file);

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log(res);
            console.log("An error occurred in File Upload");
          });
      } catch (error) {
        console.log(error);
      }
    },

    importTPS: function (file, details, token) {
      try {
        const api = config.galfarapi;
        let API = api + apiService.importTPS;

        var bodyFormData = new FormData();
        file && bodyFormData.append("master_tps", file);
        // details &&
        //   Object.keys(details).map((eachkey) => {
        //     bodyFormData.append(eachkey, details[eachkey]);
        //     return true;
        //   });

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log(res);
            console.log("An error occurred in File Upload");
          });
      } catch (error) {
        console.log(error);
      }
    },
    importCheckListItems: function (file, details, token) {
      try {
        const api = config.galfarapi;
        let API = api + apiService.importCheckListItems;

        var bodyFormData = new FormData();
        file && bodyFormData.append("checklistItem", file);

        details &&
          Object.keys(details).map((eachkey) => {
            bodyFormData.append(eachkey, details[eachkey]);
            return true;
          });

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log(res);
            console.log("An error occurred in File Upload");
          });
      } catch (error) {
        console.log(error);
      }
    },

    importCTRActivity: function (file, details, token) {
      try {
        const api = config.galfarapi;
        let API = api + apiService.importCTRActivity;

        var bodyFormData = new FormData();
        file && bodyFormData.append("ctr_activity", file);
        // details &&
        //   Object.keys(details).map((eachkey) => {
        //     bodyFormData.append(eachkey, details[eachkey]);
        //     return true;
        //   });

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log(res);
            console.log("An error occurred in File Upload");
          });
      } catch (error) {
        console.log(error);
      }
    },
    importProjectCTRActivity: function (file, details, token) {
      try {
        const api = config.galfarapi;
        let API = api + apiService.importProjectCTRActivity;

        var bodyFormData = new FormData();
        file && bodyFormData.append("project_activity", file);
        details &&
          Object.keys(details).map((eachkey) => {
            bodyFormData.append(eachkey, details[eachkey]);
            return true;
          });

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log(res);
            console.log("An error occurred in File Upload");
          });
      } catch (error) {
        console.log(error);
      }
    },
    importMasterCTR: function (file, details, token) {
      try {
        const api = config.galfarapi;
        let API = api + apiService.importMasterCTR;

        var bodyFormData = new FormData();
        file && bodyFormData.append("master_ctr", file);
        //  details &&
        //   Object.keys(details).map((eachkey) => {
        //     bodyFormData.append(eachkey, details[eachkey]);
        //     return true;
        //   });

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log(res);
            console.log("An error occurred in File Upload");
          });
      } catch (error) {
        console.log(error);
      }
    },

    // Project TPS

    getProjectTPS: function (details) {
      let API = config.galfarapi + apiService.getProjectTPS;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addProjectTPS: function (details) {
      let API = config.galfarapi + apiService.addProjectTPS;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateProjectTPS: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.updateProjectTPS + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    deleteProjectTPS: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.deleteProjectTPS + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    importProjectTPS: function (file, details, token) {
      let API = config.galfarapi + apiService.importProjectTPS;
    },
    //Project CTR Activity
    getProjectCTRActivity: function (details) {
      let API = config.galfarapi + apiService.getProjectCTRActivity;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addProjectCTRActivity: function (details) {
      let API = config.galfarapi + apiService.addProjectCTRActivity;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateProjectCTRActivity: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.updateProjectCTRActivity + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    deleteProjectCTRActivity: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.deleteProjectCTRActivity + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getRevisionProjectCTRActivity: function (details, publicId) {
      let API =
        config.galfarapi +
        apiService.getRevisionProjectCTRActivity +
        "/" +
        publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    //IDC Master
    getIDCMasters: function (details) {
      let API = config.galfarapi + apiService.getIDCMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    AddIDCMasters: function (details) {
      let API = config.galfarapi + apiService.AddIDCMasters;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateIDCMaster: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.updateIDCMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteIDCMaster: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.deleteIDCMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getIdcDeptsandEmployees: function (details, publicId) {
      let API =
        config.galfarapi + apiService.getIdcDeptsandEmployees + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },
    getIdcMatrix: function (details) {
      let API = config.galfarapi + apiService.getIdcMatrix;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },
    getIdcMatrixById: function (details, publicId) {
      let API = config.galfarapi + apiService.getIdcMatrix + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },

    importIdc: function (file, details, token) {
      try {
        const api = config.galfarapi;
        let API = api + apiService.importIdc;

        var bodyFormData = new FormData();
        file && bodyFormData.append("idc_matrix", file);
        // details &&
        //   Object.keys(details).map((eachkey) => {
        //     bodyFormData.append(eachkey, details[eachkey]);
        //     return true;
        //   });

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log(res);
            console.log("An error occurred in File Upload");
          });
      } catch (error) {
        console.log(error);
      }
    },

    updateIdcMatrix: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.updateIdcMatrix + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    getDesignationMasters: function (details) {
      let API = config.api + apiService.getDesignationMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //PLIP
    getPLIP: function (details) {
      let API = config.galfarapi + apiService.getPLIP;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    exportPlip: function (details) {
      let API = config.galfarapi + apiService.exportPlip;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    deletePLIP: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.deletePLIP + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    updatePLIP: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.updatePLIP + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    importPLIP: function (file, details, token) {
      try {
        const api = config.galfarapi;
        let API = api + apiService.importPLIP;
        var bodyFormData = new FormData();
        file && bodyFormData.append("plip", file);
        details &&
          Object.keys(details).map((eachkey) => {
            bodyFormData.append(eachkey, details[eachkey]);
            return true;
          });

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log(res);
            console.log("An error occurred in File Upload");
          });
      } catch (error) {
        console.log(error);
      }
    },

    //CTR Master
    getCTRMasters: function (details) {
      let API = config.galfarapi + apiService.getCTRMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addCTRMasters: function (details) {
      let API = config.galfarapi + apiService.addCTRMasters;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateCTRMaster: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.updateCTRMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    deleteCTRMaster: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.deleteCTRMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getCTRMastersRevisions: function (details, publicId) {
      let API =
        config.galfarapi + apiService.getCTRMastersRevisions + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //Project CTR

    getProjectCTR: function (details) {
      let API = config.galfarapi + apiService.getProjectCTR;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addProjectCTR: function (details) {
      let API = config.galfarapi + apiService.addProjectCTR;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateProjectCTR: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.updateProjectCTR + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    deleteProjectCTR: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.deleteProjectCTR + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getRevisionProjectCTR: function (details, publicId) {
      let API =
        config.galfarapi + apiService.getRevisionProjectCTR + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //Project CTd

    getProjectCTD: function (details) {
      let API = config.galfarapi + apiService.getProjectCTD;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addProjectCTD: function (details) {
      let API = config.galfarapi + apiService.addProjectCTD;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateProjectCTD: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.updateProjectCTD + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    deleteProjectCTD: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.deleteProjectCTD + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getRevisionProjectCTD: function (details, publicId) {
      let API =
        config.galfarapi + apiService.getRevisionProjectCTD + "/" + publicId;

      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    //Branch Start
    getProjectMasters: function (details) {
      let API = config.galfarapi + apiService.projectMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addProjectMaster: function (details) {
      let API = config.galfarapi + apiService.projectMasters;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateProjectMaster: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.projectMasters + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteProjectMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.deleteProjectMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    getProjectDetails: function (details, publicId) {
      console.log("API");

      let API =
        config.galfarapi + apiService.getProjectDetails + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },

    getCheckListDetails: function (details, publicId) {
      let API =
        config.galfarapi + apiService.getCheckListDetails + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },

    // Resource Pool Apis
    getResourcePools: function (details) {
      let API = config.galfarapi + apiService.getResourcePools;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    getResourcePoolsByDeptId: function (details) {
      let API = config.galfarapi + apiService.getResourcePoolsByDeptId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getDocuments: function (details) {
      let API = config.galfarapi + apiService.getDocuments;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getAllDocuments: function (details) {
      let API = config.galfarapi + apiService.getAllDocuments;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addFolderByName: function (details) {
      let API = config.galfarfileapi + apiService.addFolderByName;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    deleteFolderByName: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarfileapi + apiService.deleteFolderByName + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    addDocumentMaster: function (details) {
      let API = config.galfarapi + apiService.document;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateDocumentMaster: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.document + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    updateDocumentStamp: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.documentStampUpdate + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteDocumentMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.updateDocumentStatus + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    documentFolderTagging: function (details) {
      let API = config.galfarapi + apiService.documentFolderTagging;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getNewFolderStructure: function (details, projectId, parentId) {
      let API =
        config.galfarfileapi +
        apiService.getNewFolderStructure +
        "/" +
        projectId +
        "/" +
        parentId;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getFolderStructure: function (details) {
      let API = config.galfarfileapi + apiService.getFolderStructure;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getDepartmentMasters: function (details) {
      let API = config.api + apiService.getDepartmentMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    uploadProjectDocument: function (file, details, token) {
      try {
        const api = config.galfarfileapi;
        let API = api + apiService.uploadProjectDocument;
        var bodyFormData = new FormData();
        file && bodyFormData.append("file", file);
        details &&
          Object.keys(details).map((eachkey) => {
            bodyFormData.append(eachkey, details[eachkey]);
            return true;
          });

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log("An error occurred in File Upload");
            return { status: false, data: res?.response?.data, success: false };
          });
      } catch (error) {
        console.log(error);
      }
    },

    importProjectCTRById: function (file, details, token) {
      try {
        const api = config.galfarapi;
        let API = api + apiService.importProjectCTRById;
        var bodyFormData = new FormData();
        file && bodyFormData.append("project_ctr", file);
        details &&
          Object.keys(details).map((eachkey) => {
            bodyFormData.append(eachkey, details[eachkey]);
            return true;
          });

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log(res);
            console.log("An error occurred in File Upload");
          });
      } catch (error) {
        console.log(error);
      }
    },
    getDocumentById: function (details, id) {
      let API = config.galfarapi + apiService.document + "/" + id;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    importProjectCTDById: function (file, details, token) {
      try {
        console.log("token", token);
        const api = config.galfarapi;
        let API = api + apiService.importProjectCTDById;
        var bodyFormData = new FormData();
        file && bodyFormData.append("project_ctd", file);
        details &&
          Object.keys(details).map((eachkey) => {
            bodyFormData.append(eachkey, details[eachkey]);
            return true;
          });

        return axios({
          method: "POST",
          url: API,
          headers: {
            Authorization: token,
          },
          data: bodyFormData,
        })
          .then(function (response) {
            var resData = response.data;
            if (!resData) {
              const message = resData.msg;
              const errorRes = { status: false, msg: message };
              return errorRes;
            }
            const apiResponse = resData;
            return { status: true, data: apiResponse, success: true };
          })
          .catch(function (res) {
            console.log(res);
            console.log("An error occurred in File Upload");
          });
      } catch (error) {
        console.log(error);
      }
    },
    getDocumentById: function (details, id) {
      let API = config.galfarapi + apiService.document + "/" + id;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateDocumentVersion: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.updateDocumentVersion + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    statusUpdate: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.statusUpdate + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    //Checklist api start
    getChecklistMasters: function (details) {
      let API = config.galfarapi + apiService.checkListMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addChecklistMaster: function (details) {
      let API = config.galfarapi + apiService.checkListMasters;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateChecklistMaster: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.checkListMasters + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteCheckListMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.deleteCheckListMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getChecklistItem: function (details) {
      let API = config.galfarapi + apiService.CheckListItemMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addChecklistItem: function (details) {
      let API = config.galfarapi + apiService.CheckListItemMasters;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateChecklistItemMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.CheckListItemMasters + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteCheckListItemMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi +
        apiService.deleteCheckListItemMaster +
        "/" +
        publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    //Document Category Start
    getDocumentCategoryMasters: function (details) {
      let API = config.galfarapi + apiService.getDocumentCategoryMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addDocumentCategoryMaster: function (details) {
      let API = config.galfarapi + apiService.addDocumentCategoryMaster;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateDocumentCategoryMaster: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      let API =
        config.galfarapi +
        apiService.updateDocumentCategoryMaster +
        "/" +
        publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteDocumentCategoryMaster: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      let API =
        config.galfarapi +
        apiService.deleteDocumentCategoryMaster +
        "/" +
        publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    //Doctype api start
    getDoctypeMasters: function (details) {
      let API = config.galfarapi + apiService.doctypeMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    addDoctypeMaster: function (details) {
      let API = config.galfarapi + apiService.doctypeMasters;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    updateDoctypeMaster: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.doctypeMasters + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    deleteDoctypeMaster: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.deleteDoctypeMaster + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    // All Master Components Export APIs
    masterCtrExportAll: function (details) {
      let API = config.galfarapi + apiService.masterCtrExportAll;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    engineeringProgressSummary: function (details) {
      let API = config.galfarapi + apiService.engineeringProgressSummary;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    documentCategoryExport: function (details) {
      let API = config.galfarapi + apiService.documentCategoryExport;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    docTypeExport: function (details) {
      let API = config.galfarapi + apiService.docTypeExport;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    masterCtrActivityExport: function (details) {
      let API = config.galfarapi + apiService.masterCtrActivityExport;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    masterCtdExport: function (details) {
      let API = config.galfarapi + apiService.masterCtdExport;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    checkListItemExport: function (details, checklistId) {
      let API = config.galfarapi + apiService.checkListItemExport + "/" + checklistId;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    
    masterIdcExport: function (details) {
      let API = config.galfarapi + apiService.masterIdcExport;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    engineeringProgressOverallProgressReport: function (details, projectId) {
      let API = config.galfarapi + apiService.engineeringProgressOverallProgressReport + "/" + projectId;
      let payload = {
        ...details,
      };

      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },

    //review
    getReviewsByUser: function (details) {
      let API = config.galfarapi + apiService.getReviewsByUser;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getReviewsList: function (details) {
      let API = config.galfarapi + apiService.getReviewsList;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getTotalReviews: function (details) {
      let API = config.galfarapi + apiService.getTotalReviews;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getIDCPending: function (details) {
      let API = config.galfarapi + apiService.getIDCPending;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getReviewById: function (details, id) {
      let API = config.galfarapi + apiService.getReviewsByUser + "/" + id;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateReviewStatus: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi + apiService.updateReviewStatus + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    assignBackCheck: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.assignBackCheck + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    addDocumentWorkflow: function (details) {
      let API = config.galfarapi + apiService.eventWorkflow;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateDocumentWorkflow: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.eventWorkflow + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    addWorkflowUsers: function (details) {
      let API = config.galfarapi + apiService.addWorkflowUsers;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
      }).then((res) => {
        return res;
      });
    },
    getDocumentWorkflowsByUser: function (details) {
      let API = config.galfarapi + apiService.eventWorkflow;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addWorkflowComment: function (details) {
      let API = config.galfarapi + apiService.workflowComment;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateWorkflowComment: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.workflowComment + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    updateWorkflowCommentStatus: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      let API =
        config.galfarapi +
        apiService.updateWorkFlowCommentStatus +
        "/" +
        publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    //Technical Comments
    addTechnicalComment: function (details) {
      let API = config.galfarapi + apiService.technicalComments;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateTechnicalComment: function (details, publicId, concurrencyStamp) {
      let API = config.galfarapi + apiService.technicalComments + "/" + publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    updateTechnicalCommentStatus: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      let API =
        config.galfarapi +
        apiService.updateTechnicalCommentsStatus +
        "/" +
        publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },

    updateEventWorkflowStatus: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi +
        apiService.updateEventWorkflowStatus +
        "/" +
        publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    updateEventWorkFlowDueDate: function (details, publicId, concurrencyStamp) {
      let API =
        config.galfarapi +
        apiService.updateEventWorkFlowDueDate +
        "/" +
        publicId;
      let payload = {
        ...details,
      };
      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getCheckListItemChoose: function (details) {
      let API = config.galfarapi + apiService.getCheckListItemChoose;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getDocumentCount: function (details) {
      let API = config.galfarapi + apiService.getDocumentCount;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getSelfDocMasters: function (details) {
      let API = config.galfarapi + apiService.getSelfDocMasters;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    addPdoComment: function (details) {
      let API = config.galfarapi + apiService.pdoComment;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getDocumentHistory: function (details) {
      let API = config.api + apiService.getDocumentHistory;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    downloadDocumentIdc: function (details) {
      let API = config.galfarapi + apiService.downloadDocumentIdc;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    downloadDocumentIdcPdf: function (details) {
      let API = config.galfarapi + apiService.downloadDocumentIdcPdf;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    downloadDocumentDdr: function (details) {
      let API = config.galfarapi + apiService.downloadDocumentDdr;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getDwgFileBuffer: function (details) {
      let API = config.galfarfileapi + apiService.getDwgFileBuffer;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getFolderPermissions: function (details) {
      let API = config.galfarfileapi + apiService.getFolderPermissions;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    getFolderRolePermission: function (details) {
      let API = config.galfarfileapi + apiService.getFolderRolePermission;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    updateFolderRolePermissions: function (
      details,
      publicId,
      concurrencyStamp
    ) {
      let API = config.galfarfileapi + apiService.updateFolderRolePermissions;

      let payload = {
        ...details,
      };

      let type = "PATCH";
      return AxiosService({
        API,
        payload,
        authNeeded: true,
        type,
        concurrencyStamp,
      }).then((res) => {
        return res;
      });
    },
    getVendorDocTypes: function (details) {
      let API = config.galfarapi + apiService.getVendorDocTypes;
      let payload = {
        ...details,
      };
      let type = "GET";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
    signatureByReviewer: function (details) {
      let API = config.galfarapi + apiService.documentStampByReviewer;
      let payload = {
        ...details,
      };
      let type = "POST";
      return AxiosService({ API, payload, authNeeded: true, type }).then(
        (res) => {
          return res;
        }
      );
    },
  };
  return Services;
}
