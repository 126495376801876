import React, { useEffect, useState } from "react";
import "./documentList.scss";
import { useLocation, useNavigate } from "react-router-dom";
import MainServices from "../../../services/MainServices";
import axios from "axios";
import useCurrentPage from "../../../hooks/useCurrentPage";
import editicon from "../../../assets/images/icons/edit.svg";
import removeicon from "../../../assets/images/icons/statusChange.svg";
import viewicon from "../../../assets/images/icons/viewicon.svg";
import searchicon from "../../../assets/images/icons/search-normal.svg";
import { Table } from "reactstrap";
import IconButton from "../../../assets/elements/IconButton";
import Pagination from "../../../assets/elements/CustomPagination";
import SuccessModal from "../../../assets/elements/SuccessModal";
import moment from "moment";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import PageLoader from "../../../assets/loaders/PageLoading";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import {
  paramsToFilters,
  paramsToFiltersObj,
  filtersToUri,
  filtersToUrlParams,
} from "../../../assets/functions/filters";
import { squareBackIcon } from "../../../assets/images/icons/icons";

import { notifySuccess } from "../../../assets/elements/Toast";
import { Tooltip } from "react-tooltip";

import { InputGroup, Form, Button, Modal,Dropdown } from "react-bootstrap";
import ArrowBack from "../../../assets/images/Galfar/vuesax/linear/arrow-left.svg";
import options from '../../../assets/images/icons/options.svg'
import ProjectsDropdown from "../projects/ProjectDropdown";

export default function DocumentList() {
  const [pageLoading, setPageLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const location = useLocation();
  const { 
    getProjectDetails,
    downloadProgressReport,
    getDocuments, 
    deleteDocumentMaster
   } = MainServices();
  const { updateCurrentPage } = useCurrentPage();
  const [documents, setDocuments] = useState();
  const [filters, setFilters] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
  });
  const [selectedStatus, setSelectedStatus] = useState("ALL");
  const [searchString, setSearchString] = useState("");
  const [totalDocumentCount, setTotalDocumentCount] = useState(0);
  
  const [project, setProject] = useState();

  const allStatus = [
    { label: "All", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];
  const { grantPermission } = PermissionFunctions();
  const navigate = useNavigate();
  const { deBounce } = TimingFunctions();

  const params = new URLSearchParams(location.search);

  const changedParams = Object.fromEntries(params);
  const projectIdd = new URLSearchParams(location.search).get("projectId");
  const originalStatuss=new URLSearchParams(location.search).get("originalStatus")
  const [selectedDocument, setSelectedDocument] = useState();
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [deliverableType, setDeliverableType] = useState("all");
  const [projectId,setProjectId]=useState(projectIdd)
  const [originalStatus,setOriginalStatus]=useState(originalStatuss)

  const originalStatusList=[
   
    {label:'Pending',value:'pending'},
    {label:'Draft',value:'draft'},
    {label:'IFR',value:'IFR'},
    {label:'IFA',value:'IFA'},
    {label:'IDC',value:'IDC'},
    {label:'APP',value:'APP'},
    {label:'AFC',value:'AFC'},
  ]

  useEffect(() => {
    updateCurrentPage({
      title: "Document List",
      subtitle: "",
    });
    // eslint-disable-next-line
  }, []);
 

  useEffect(() => {
    let filters = paramsToFilters(changedParams?.filters, "filters");
    let filtersObj = paramsToFiltersObj(changedParams?.filters);
    setSelectedStatus(filtersObj?.status?.status || "");
    setDeliverableType(filtersObj?.deliverable?.deliverable || "all");
    setFilters(filtersObj);
    setSearchString(filtersObj?.name?.name);
    setPagination({
      page: changedParams?.page || 1,
      limit: changedParams?.limit || 50,
    });

    getDocumentsByProjectId({
      page: changedParams?.page || 1,
      limit: changedParams.limit || 50,
      filters: [...filters, 
        { key: "projectId", eq: projectId }, 
        {key:'ddr',eq:true},
       ...(originalStatus ? [{key:'originalStatus',eq:originalStatus}]:[])],
    });
    // eslint-disable-next-line
  }, [
    changedParams.page,
    changedParams.limit,
    changedParams.filters,
    changedParams.projectId,
    originalStatus
  ]);

  // useEffect(() => {
  //     if (projectId) {
  //         getDocumentsByProjectId({ page: 1, limit: 20, filters: [{ key: 'projectId', eq: projectId }] });
  //     }
  // }, [projectId])
  useEffect(() => {
    if (projectId) getProjectDetailsPage();

    // eslint-disable-next-line
  }, []);


  const getProjectDetailsPage = async () => {
    setPageLoading(true);
    const details = {};
    await axios
      .all([getProjectDetails(details, projectId)])
      .then(function (res) {
        if (res) {
          let resData = res[0];
          if (resData.success) {
            resData = resData.data ? resData.data : {};
            setProject(resData);

            setPageLoading(false);
          } else {
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Projects details");
      });
  };

  const handleProgressReportDownloadClick = async () => {
    const details = {
      projectId
    }

    await axios.all([downloadProgressReport(details)]).then(response => {
      if (response.length > 0) {
        const data = response[0].data
        const fileName = data.response['fileName']
        console.log(fileName);
        if (fileName) {

          const path = `https://files.appsarizona.com/galfar-apis/server/exports/engineer/${fileName}`
          console.log(path)
          downloadProgressFile(path);
        } else {
          console.error('File path not found in response.');
        }
      } else {
        console.error('File path not found in response.');
      }
    }).catch(error => {
      console.error('Error fetching file:', error);
    });
  }

  const downloadProgressFile = (filePath) => {
    const link = document.createElement('a');
    link.href = filePath;
    link.setAttribute('download', 'progress_report.xlsx'); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };


  const getDocumentsByProjectId = async ({ page, limit, filters }) => {
    if(filters && Array.isArray(filters)){
      filters=[...filters,{key:'status',eq:'active'}]
    }else{
      filters=[{key:'status',eq:'active'}]
    }
    setPageLoading(true);
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters,
      sorting:[{key:'created_at',direction:'asc'}]
    };
    await axios
      .all([getDocuments(details)])
      .then(function (res) {
        let resData = res[0].data ? res[0].data : [];
        let totalCount = res[0]?.totalCount || 0;

        setTotalDocumentCount(totalCount);
        resData = resData.map((_, index) => {
          return {
            ..._,
            sNo: index + 1 + (page - 1) * limit,
          };
        });

        setDocuments(resData);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onStatusChange = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      status: { status: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      projectId,
    });
    navigate({
      search,
    });
  };
  const onDeliverableType = (e) => {
    let mfilters;
    if (e.target.value === "Yes") {
      mfilters = filtersToUri({
        ...filters,
        deliverable: { deliverable: e.target.value },
      });
    } else {
      const { deliverable, ...rfiltesrs } = filters;
      mfilters = filtersToUri({
        ...rfiltesrs,
      });
    }

    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      projectId,
    });
    navigate({
      search,
    });
  };
  //pagination
  const onPage = (page) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page,
      limit: pagination.limit,
      filters: mfilters,
    });
    navigate({ search });
  };

  const onRowsPerPage = (rows) => {
    let mfilters = filtersToUri(filters);
    let search = filtersToUrlParams({
      page: pagination.page,
      limit: rows,
      filters: mfilters,
    });
    navigate({ search });
  };

  const searchDocuments = (e) => {
    deBounce(() => onSearchFilter(e), 1000);
    setSearchString(e.target.value);
  };

  const onSearchFilter = (e) => {
    let mfilters = filtersToUri({
      ...filters,
      documentName: { documentName: e.target.value },
    });
    let search = filtersToUrlParams({
      page: 1,
      limit: pagination.limit,
      filters: mfilters,
      projectId,
    });
    navigate({
      search,
    });
  };

  const onDeleteDocument = (selected) => {
    if (selected) {
      setSelectedDocument(selected);
    } else {
      setSelectedDocument("");
    }
    setDeleteDocumentModal(!deleteDocumentModal);
  };

  const deleteDocument = async () => {
    setButtonLoading(true);
    const details = {
      status: "inactive",
    };
    await axios
      .all([
        deleteDocumentMaster(
          details,
          selectedDocument.publicId,
          selectedDocument.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            getDocumentsByProjectId({
              page: pagination.page,
              limit: pagination.limit,
              filters: [{ key: "projectId", eq: projectId }],
            });
            onDeleteDocument();
            notifySuccess("Document Deleted Successfully");
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        if (res.message) console.log("An error occurred in deleting Document");
      });
  };
  return (
    <div className="Document-list-main">
      <div className="admin-popular-main">
        <div className="header">
          <div className="d-flex gap-1 align-items-center">
            <div className="back-btn-function">
              <img src={ArrowBack} alt="" onClick={() => navigate(-1)} />
            </div>
            <p className="content-header-main">Documents</p>
          </div>
          <div className="d-flex gap-3">
            <InputGroup style={{ width: "auto" }}>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search"
                className="search-input-main"
                onChange={(e) => searchDocuments(e)}
              />
              <InputGroup.Text id="search" className="search-input-main">
                <img src={searchicon} alt="search icon" />
              </InputGroup.Text>
            </InputGroup>

            <div className="product-list-header-flex" style={{ width: "8rem" }}>
              <select
                class="form-control form-select custom-status-select"
                id="fv-topics"
                name="status"
                required
                onChange={onDeliverableType}
                value={deliverableType}
              >
                 <option value="all" key="all">
                  All
                </option>
                <option value="Yes" key="dd">
                  Deliverables
                </option>
               
              </select>
            </div>
            <div className="product-list-header-flex" style={{ width: "8rem" }}>
              <select
                class="form-control form-select custom-status-select"
                id="fv-topics"
                name="status"
                data-placeholder="Select a option"
                required
                onChange={onStatusChange}
                value={selectedStatus}
              >
                <option label="Select Status" value=""></option>
                {allStatus.map((each) => (
                  <option value={each.value} key={each.value}>
                    {each.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="product-list-header-flex" style={{ width: "12em" }}>
              <select
                class="form-control form-select custom-status-select"
                id="fv-topics"
                name="status"
                data-placeholder="Select a option"
                required
                onChange={(e)=>{
                  setOriginalStatus(e.target.value)
                }}
               // onChange={onStatusChange}
             //   value={selectedStatus}
             value={originalStatus?originalStatus:''}
              >
                <option label='--Select Original Status--' value=''></option>
                {originalStatusList.map((each) => (
                  <option value={each.value} key={each.value}>
                    {each.label}
                  </option>
                ))}
              </select>
            </div>

            {grantPermission("Document_write") && (
              <IconButton
                iconType="add"
                title="Add Document"
                onClick={() =>
                  navigate({
                    pathname: "/documents/add-document",
                    search: `?projectId=${projectId}`,
                  })
                }
              />
            )}
            <ProjectsDropdown
            projectId={project?.publicId}
            projectType={project?.projectType}
            page="documen-list"
          />
          </div>
        </div>
        {pageLoading ? (
          <PageLoader />
        ) : documents && documents.length > 0 ? (
          <div className="content">
            <div className="table-main">
              <Table responsive bordered className="table table-bordered">
                <thead className="service-table-head">
                  <tr>
                    <th>Sno.</th>
                    <th>Document Name</th>
                    <th>Deliverable Code</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Status</th>
                    <th>Original Status</th>
                    {(grantPermission("Document_delete") ||
                      grantPermission("Document_update")) && <th style={{width : "100px"}}>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {documents.map((each) => {
                    return (
                      <tr>
                        <td>{each?.sNo}</td>
                        <td
                          role="button"
                          style={{ color: "#E65C4F" }}
                          onClick={() =>
                            navigate({
                              pathname: "/documents/document-details",
                              search: `?documentId=${each.publicId}`,
                            })
                          }
                        >
                          {each?.documentName}
                        </td>
                        <td>{each?.masterCtr?.code}</td>

                        <td>{each?.author?.empName}</td>
                        <td>{moment(each?.createdAt).format("DD-MM-YYYY")}</td>
                        <td>{each?.status}</td>
                        <td>{each?.originalStatus}</td>
                        {(grantPermission("Document_delete") ||
                          grantPermission("Document_update")) && (
                          <td className="options">
                            <div className="d-flex justify-content-around">
                              {grantPermission("Document_update") &&
                                each?.status === "active" && (
                                  <div
                                    onClick={() =>
                                      navigate({
                                        pathname: "/documents/add-document",
                                        search: `documentId=${each?.publicId}&projectId=${projectId}`,
                                      })
                                    }
                                    style={{ cursor: "pointer" }}
                                    data-tooltip-id="editTooltip"
                                    data-tooltip-content="Edit Document"
                                  >
                                    <Tooltip id="editTooltip" place="top" effect="solid" />
                                    <img src={editicon} alt="Edit icon" />
                                  </div>
                                )}
                              {grantPermission("Document_delete") && (
                                <div
                                  onClick={() => onDeleteDocument(each)}
                                  style={{ cursor: "pointer" }}
                                  data-tooltip-id="statusChangeTooltip"
                                  data-tooltip-content={each?.status === 'active' ? 'Inactivate Document' : 'Activate Document'}
                                >
                                  <Tooltip id="statusChangeTooltip" place="top" effect="solid" />
                                  <img src={removeicon} alt="Remove icon" />
                                </div>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        ) : (
          <div className="no-data-found-main">No Documents Found</div>
        )}
        {documents && documents.length > 0 && (
          <div class="custom-pagination-main">
            <div class="card-inner">
              <Pagination
                totalCount={totalDocumentCount}
                currentPage={pagination.page}
                rowsPerPage={pagination.limit}
                onNext={onPage}
                onPrev={onPage}
                onPageClick={onPage}
                onRowsPerPage={onRowsPerPage}
              />
            </div>
          </div>
        )}
      </div>
      <Modal
        centered
        show={deleteDocumentModal}
        onHide={onDeleteDocument}
        className="add-department-modal-main"
        selected={selectedDocument}
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            Delete Document
          </Modal.Title>
        </Modal.Header>

        {deleteDocumentModal && (
          <Modal.Body className="add-department-modal-body">
            <p>
              Do you want to delete this Document{" "}
              <span style={{ color: "#9d72ff", textDecoration: "underline" }}>
                {selectedDocument.documentName}
              </span>
              ?
            </p>

            <div className="mt-3">
              <div className="d-flex gap-2">
                <Button
                  className="back-icon-btn-main"
                  onClick={onDeleteDocument}
                >
                  <span>{squareBackIcon}</span>
                  <span className="ms-2">Back</span>
                </Button>
                <Button className="theme-btn-main" onClick={deleteDocument}>
                  {buttonLoading ? <ButtonLoading /> : "Delete"}
                </Button>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
}
