import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainServices from "../../../services/MainServices";
import axios from "axios";
import PageLoader from "../../../assets/loaders/PageLoading";
import "./newRepository.scss";
import { Button, Table, Label, Input } from "reactstrap";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import AsyncSelect, { useAsync } from "react-select/async";
import Backicon from "../../../assets/images/icons/backicon.svg";
import TimingFunctions from "../../../assets/functions/TimingFunctions";
import useCurrentPage from "../../../hooks/useCurrentPage";
import moment from "moment";
import * as Yup from "yup";
import useAuth from "../../../hooks/useAuth";
import foldericon from "../../../assets/images/icons/folders-bg.svg";
import plusInsideCircleIcon from "../../../assets/images/icons/plus-inside-circle.svg";
import { PrimaryInput } from "../../../assets/FormElements";
import circleDeleteIcon from "../../../assets/images/icons/circle-delete.svg";
import { downloadFileWithFileName } from "../../../assets/functions/Download";
import useConfig from "../../../hooks/useConfig";
import Pagination from "../../../assets/elements/CustomPagination";
import ArrowBack from "../../../assets/images/Galfar/vuesax/linear/arrow-left.svg";

export default function NewRepository() {
  const addFolderSchema = Yup.object().shape({
    name: Yup.string().required("Folder Name is Required"),
  });

  const documentInfoSchema = Yup.object().shape({
    documentName: Yup.string().required("Project Name is Required"),
    documentNumber: Yup.string().required("Document number is Required"),
    documentType: Yup.mixed().required("Type is Required"),
    uploadFile: Yup.mixed().required("File  is Required"),
  });

  useEffect(() => {
    updateCurrentPage({
      title: "Documents",
      subtitle: "Repository",
    });
    // eslint-disable-next-line
  }, []);

  const {
    getNewFolderStructure,
    documentFolderTagging,
    getDocumentCategoryMasters,
    getVendorDocTypes,
    getDoctypeMasters,
    getUserDetails,
    uploadProjectDocument,
    addDocumentMaster,
    addFolderByName,
    deleteFolderByName,
    getFolderRolePermission,
  } = MainServices();
  const location = useLocation();
  const navigate = useNavigate();
  const projectIdd = new URLSearchParams(location.search).get("projectId");
  const [folderStructure, setFolderStructure] = useState([]);
  const [path, setPath] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [lastNode, setLastNode] = useState(false);
  const [filesOfFolder, setFilesOfFolder] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [projectId, setProjectId] = useState(projectIdd);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [documentModel, setDocumentModel] = useState(false);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [vendorDocTypes, setVendorDocTypes] = useState([]);
  const uploadRef = useRef(null);
  const { deBounce } = TimingFunctions();
  const [userDepartment, setUserDepatrment] = useState();
  const { updateCurrentPage } = useCurrentPage();
  const { user } = useAuth();
  const [selectedPath, setSelectedPath] = useState("");
  const initialValue = new URLSearchParams(location.search).get("initial");
  const parentId = new URLSearchParams(location.search).get("parentId");
  const [pathForAddingNewFolder, setPathForAddingNewFolder] = useState();
  const [parentIdForAddingNewFolder, setParentIdForAddingNewFolder] =
    useState();
  const [addFolderClick, setAddFolderClick] = useState(false);
  const [deleteFolderClick, setDeleteFolderClick] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState();
  const [folderRolePermissions, setFolderRolePermissions] = useState([]);
  const [roleId, setRoleId] = useState();
  const [projectName, setProjectName] = useState();
  const [parentDetails, setParentDetails] = useState();
  const [isVendor, setIsVendor] = useState(false);
  const [isAFC, setIsAFC] = useState(false);
  const [demoDocument, setDemoDocument] = useState("");
  const config = useConfig("");
  const [page, setPage] = useState(1);
  const [documentsCount, setDocumentsCount] = useState(0);
  const [search, setSearch] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    console.log(initialValue);
    if (initialValue === "true") {
      getProjectDetailsByProjectId(projectId);
    } else {
      getNewFolderStructureByProjectId(projectId, parentId);
    }
    getDocumentCategories({});
    getDocumentTypes({});
    getUserDetailsById();
    getAllFolderRolePermissions();
  }, [initialValue, projectId, parentId]);

  const getAllFolderRolePermissions = async () => {
    const details = {
      roleId,
      pageSize: 2000,
      pageNumber: 1,
      filters: [{ key: "projectId", eq: projectIdd }],
    };

    await axios
      .all([getFolderRolePermission(details)])
      .then((res) => {
        let resData = res[0]?.data?.data;
        resData = resData.map((each) => {
          return {
            path: each?.path,
            permission: each?.folderPermission?.name,
          };
        });
        console.log("folder role permissions", resData);
        setFolderRolePermissions(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(demoDocument);

  const getUserDetailsById = async () => {
    const details = {};
    const token = user.token;
    await axios.all([getUserDetails(details, token)]).then(function (res) {
      if (res[0]) {
        let resData = res[0];
        let message = resData.message;
        setRoleId(resData?.data?.roleId);

        if (resData.success) {
          let department = resData?.data?.deptartment;
          department.label = department.deptName;
          department.value = department.publicId;

          setUserDepatrment(department);
        }
      }
    });
  };

  const getDocumentTypes = async ({ page, limit, filters }) => {
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters,
    };
    let options = await axios
      .all([getDoctypeMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let types = resData.data ? resData.data : [];
            types = types.map((_, index) => {
              return {
                ..._,
                label: _.docTypeName,
                value: _.publicId,
              };
            });

            !filters && setTypes(types);
            return types;
          } else {
            return [];
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Document types");
      });
    return options;
  };
  const getVendorDocumentTypes = async ({ page, limit, filters }) => {
    const details = {
      pageSize: limit || 500,
      pageNumber: page || 1,
      filters,
    };
    let options = await axios
      .all([getVendorDocTypes(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let types = resData.data?.data ? resData.data?.data : [];
            types = types.map((_, index) => {
              return {
                ..._,
                label: _.code,
                value: _.publicId,
              };
            });

            !filters && setVendorDocTypes(types);
            return types;
          } else {
            return [];
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Document types");
      });
    return options;
  };
  const getDocumentCategories = async ({ page, limit, filters }) => {
    const details = {
      pageSize: limit || 50,
      pageNumber: page || 1,
      filters,
    };
    let options = await axios
      .all([getDocumentCategoryMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let departments = resData.data ? resData.data : [];
            departments = departments.map((_, index) => {
              return {
                ..._,
                label: _.name,
                value: _.publicId,
              };
            });
            !filters && setCategories(departments);
            return departments;
          } else {
            return [];
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Categories");
      });

    return options;
  };

  const getProjectDetailsByProjectId = async (projectId) => {
    setPageLoading(true);

    const details = {};

    await axios
      .all([getNewFolderStructure(details, projectId)])
      .then((res) => {
        if (res[0]) {
          let resData = res[0]?.data?.data?.children[0];
          if (resData) {
            let parentId = resData?.public_id;

            setProjectName(resData?.name);

            setPath((prev) => [...prev, resData]);

            navigate({
              search: `projectId=${projectId}&parentId=${parentId}`,
            });
          }
          setPageLoading(false);

          //  getNewFolderStructureByProjectId(projectId, parentId)
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };

  const getFilesofFolder = async (folderPath, page, documentName, loading) => {
    const modifiedFolderPath = folderPath.replace("./Projects/", "");

    setSearchLoading(loading);
    const details = {
      projectId,
      folderPath: modifiedFolderPath,
      pageNumber: page || 1,
      pageSize: 20,
      documentName,
    };
    await axios
      .all([documentFolderTagging(details)])
      .then((res) => {
        if (res[0]) {
          let total = res[0].totalCount || 0;
          setDocumentsCount(total);
          let resData = res[0].data || [];
          let demoDocument = resData.find((each) => each.vendor);
          setDemoDocument(demoDocument);
          resData = resData.filter((each) => !each.vendor);
          resData = resData.map((each, index) => {
            return {
              ...each,
              sNo: index + 1 + (page - 1) * 20,
            };
          });
          setLastNode(true);
          setSearchLoading(false);
          setFilesOfFolder(resData);
        }
      })
      .catch((err) => console.log(err));
  };

  const getNewFolderStructureByProjectId = async (projectId, parentId) => {
    setPageLoading(true);
    const details = {};

    await axios
      .all([getNewFolderStructure(details, projectId, parentId)])
      .then((res) => {
        if (res[0]) {
          let resData = res[0]?.data?.data?.parent;
          //  setPath((prev) => [...prev, res[0]?.data?.data.parent]);
          setParentDetails(resData);
          console.log("parent Details", resData);

          let pathOfFolder = res[0]?.data?.data?.parentsPath;
          let reversedPath = pathOfFolder.reverse();
          setPath(reversedPath);
          let match = reversedPath.find(
            (each) => each.name.toUpperCase() === "VENDOR"
          );
          if (match) {
            getVendorDocumentTypes({});
          }
          setIsVendor(match);
          let isAFC = reversedPath.find(
            (each) => each.name.toUpperCase() === "AFC (PDF & NATIVE FILES)"
          );
          setIsAFC(isAFC);
          let projectFolderForPermission = resData?.children;
          projectFolderForPermission = projectFolderForPermission?.map(
            (each) => {
              return {
                ...each,
                path: each?.path.replace(`./Projects/${projectName}/`, ""),
              };
            }
          );

          setFolderStructure(projectFolderForPermission);

          if (resData.children.length === 0) {
            setLastNode(true);
            getFilesofFolder(resData?.path, 1);
            setPage(1);
          }
          setParentIdForAddingNewFolder(resData.public_id);
          console.log("path for adding new folder", resData?.path);
          setPathForAddingNewFolder(resData?.path);
          setSelectedPath(resData?.path.replace(/^\.\/Projects\/[^\/]+\//, ""));
          setPageLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setPageLoading(false);
      });
  };
  const setPathAndGetFolderStructure = (each) => {
    //  setPath((prev) => [...prev, each]);
    navigate({
      search: `projectId=${projectId}&parentId=${each.public_id}`,
    });
    // getNewFolderStructureByProjectId(projectId, each.public_id)
  };

  const onDocumentModel = () => {
    setDocumentModel(!documentModel);
  };
  const loadDebounceOptions = (inputValue, callback, type) => {
    deBounce(() => loadOptions(inputValue, callback, type), 1000);
  };

  const loadOptions = async (inputValue, callback, type) => {
    if (!inputValue) {
      if (type === "doc_type") {
        callback(types);
      } else {
        callback(categories);
      }
    }

    let options = [];
    if (type === "doc_type") {
      let filter = [
        // {
        //   key: "status",
        //   eq: "active",
        // },
        {
          key: "docTypeName",
          iLike: inputValue,
        },
      ];
      options = await getDocumentTypes({ filters: filter });
    }
    if (type === "category") {
      let filter = [
        {
          key: "status",
          eq: "active",
        },
        {
          key: "name",
          iLike: inputValue,
        },
      ];
      options = await getDocumentCategories({ filters: filter });
    }

    callback(options);
  };
  const loadVendorDebounceOptions = (inputValue, callback, type) => {
    deBounce(() => loadVendorOptions(inputValue, callback, type), 1000);
  };

  const loadVendorOptions = async (inputValue, callback, type) => {
    if (!inputValue) {
      callback(vendorDocTypes);
    }

    let options = [];

    let filter = [
      {
        key: "status",
        eq: "active",
      },
      {
        key: "code",
        iLike: inputValue,
      },
    ];
    options = await getVendorDocumentTypes({ filters: filter });

    callback(options);
  };

  const uploadDocument = async (values) => {
    setButtonLoading(true);
    const details = {
      deliverableType: selectedPath,
      projectId,
    };
    const file = values.uploadFile;
    await axios
      .all([uploadProjectDocument(file, details, user.token)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];

          if (resData.success) {
            let data = resData.data || {};
            if (data?.filePath) {
              addDocument(values, data.filePath);
            }
          } else {
            notifyWarn(resData?.data?.error);
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in uploading document");
      });
  };
  const addDocument = async (values, filePath) => {
    setButtonLoading(true);
    const details = {
      documentName: values?.documentName,
      documentNumber: values?.documentNumber,
      documentVersion: 1,
      category: values?.category?.value,
      discipline: userDepartment?.value,
      deliverableType: selectedPath,
      documentDescription: "document",
      deliverable: "No",

      projectId,
      filePath,
      ...(isVendor
        ? {
            vendorDocumentType: values?.documentType?.value,
            documentStatus: "Technical check completed",
            originalStatus: "IFA",
          }
        : { documentType: values?.documentType?.value }),
    };

    await axios
      .all([addDocumentMaster(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            notifySuccess("Document Added Successfully");
            onDocumentModel();
            getFilesofFolder(selectedPath, 1);
            setPage(1);
            setButtonLoading(false);
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in adding Document");
      });
  };

  const setNewPath = (each) => {
    setLastNode(false);
    setFilesOfFolder([]);
    const length = path.indexOf(each);
    setPath((prevPath) => prevPath.filter((_, index) => index < length + 1));
    navigate({
      search: `projectId=${projectId}&parentId=${each.public_id}`,
    });

    //getNewFolderStructureByProjectId(projectId, each.public_id)
  };
  const onDeleteFolderClick = (selected) => {
    if (selected) {
      setSelectedFolder(selected);
    } else {
      setSelectedFolder("");
    }
    setDeleteFolderClick(!deleteFolderClick);
  };

  const onAddFolderClick = () => {
    setAddFolderClick(!addFolderClick);
  };

  const addFolder = async (values) => {
    setButtonLoading(true);
    const details = {
      name: values.name,
      path: pathForAddingNewFolder,
      parentId: parentIdForAddingNewFolder,
      projectId,
    };
    await axios
      .all([addFolderByName(details)])
      .then((res) => {
        setButtonLoading(false);
        setAddFolderClick(!addFolderClick);
        getNewFolderStructureByProjectId(projectId, parentId);
      })
      .catch((err) => console.log(err));
  };
  const deleteFolder = async () => {
    setButtonLoading(true);

    const details = {
      status: "inactive",
    };
    const publicId = selectedFolder?.public_id;
    const concurrencyStamp = selectedFolder?.concurrency_stamp;
    await axios
      .all([deleteFolderByName(details, publicId, concurrencyStamp)])
      .then((res) => {
        setButtonLoading(false);
        setDeleteFolderClick(!deleteFolderClick);
        getNewFolderStructureByProjectId(projectId, parentId);
      })
      .catch((err) => {
        console.log(err);
        setButtonLoading(false);
      });
  };

  const grantFolderPermission = (permission, path) => {
    console.log("each folder details", permission, path);

    return folderRolePermissions.some(
      (item) => item.path.includes(path) && item.permission === permission
    );
  };

  return (
    <div>
      <div
        className="d-flex justify-content-end"
        style={{ position: "relative", display: "inline-block" }}
      >
        <input
          placeholder="Search..."
          className="form-control w-25"
          onChange={(e) => {
            setSearch(e.target.value);
            if (selectedPath) {
              deBounce(() => {
                getFilesofFolder(selectedPath, 1, e.target.value, true);
              }, 500);
            }
          }}
          loading={searchLoading}
        />
        {searchLoading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: "10px",
              transform: "translateY(-50%)",
            }}
          >
            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
          </div>
        )}
      </div>
      {pageLoading ? (
        <PageLoader />
      ) : (
        <div className="new-repository-main">
          <div className="back-btn-function mb-3">
            <img
              src={ArrowBack}
              alt=""
              onClick={() =>
                navigate({
                  pathname: "/project-management/projects/project-preview",
                  search: `?projectId=${projectId}`,
                })
              }
            />
          </div>
          <div className="header">
            <p className="content-header-main">
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                {path?.map((each) => (
                  <div
                    key={each.name}
                    onClick={() => setNewPath(each)}
                    style={{ cursor: "pointer", textAlign: "left" }}
                  >
                    <h6
                      style={{
                        margin: "0 3px",
                        textAlign: "left",
                        color:"#000",
                        fontWeight:600
                      }}
                    >
                      /{each.name}
                    </h6>
                  </div>
                ))}
              </div>
            </p>
            <hr
              style={{
                background: "#BFBEC5",
                margin: "16px 0px 32px",
              }}
            />
            <div className="d-flex gap-2 d-flex justify-content-end">
              {
                //selectedPath &&
                lastNode &&
                  isVendor &&
                  demoDocument &&
                  demoDocument.filePath && (
                    <div className="d-flex justify-content-end ">
                      <Button
                        color="primary"
                        onClick={() =>
                          window.open(
                            `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                              config.documentPath + demoDocument.filePath
                            )}`,
                            "_blank"
                          )
                        }
                      >
                        Idc
                      </Button>
                    </div>
                  )
              }

              {
                //selectedPath &&
                lastNode && (
                  <div className="d-flex justify-content-end ">
                    <Button color="primary" onClick={onDocumentModel}>
                      Add Document
                    </Button>
                  </div>
                )
              }
            </div>

            <div className="d-flex gap-2"></div>
          </div>

          <div className="px-3 py-2">
            <div className="d-flex gap-4 flex-wrap mt-4">
              {folderStructure &&
                folderStructure.length > 0 &&
                !lastNode &&
                folderStructure.map((each) => {
                  return (
                    (grantFolderPermission(`${each.name}_read`, each.path) ||
                      each.custom_folder) && (
                      <div
                        style={{
                          cursor: "pointer",
                          position: "relative",
                          width: 120,
                          height: 120,
                        }}
                        key={each.public_id}
                      >
                        <div
                          style={{
                            position: "absolute",
                            right: 0,
                            bottom: 25,
                            color: "#fff",
                            zIndex: 999,
                          }}
                        ></div>
                        {(grantFolderPermission(
                          `${each.name}_delete`,
                          each.path
                        ) ||
                          each.custom_folder) && (
                          <div
                            className="delete-icon"
                            onClick={() => onDeleteFolderClick(each)}
                          >
                            <img src={circleDeleteIcon} />
                          </div>
                        )}
                        <div
                          className="folder-view-main"
                          onClick={() => setPathAndGetFolderStructure(each)}
                          style={{}}
                        >
                          <div className="folder-view-content-main"></div>
                        </div>
                        <p className="folder-view-project-name">{each?.name}</p>
                      </div>
                    )
                  );
                })}

              {!lastNode &&
                ((parentDetails &&
                  parentDetails.name === projectName &&
                  grantFolderPermission(
                    "Master_create",
                    "./Projects/Master"
                  )) ||
                  (parentDetails &&
                    grantFolderPermission(
                      `${parentDetails?.name}_create`,
                      parentDetails.path.replace(
                        `./Projects/${projectName}/`,
                        ""
                      )
                    ))) && (
                  <div
                    className="add-folder-icon-main"
                    title="Add Folder"
                    onClick={onAddFolderClick}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="folder-view-main"></div>
                    <div className="add-icon-to-folder">
                      <img
                        src={plusInsideCircleIcon}
                        style={{ height: "30px", width: "30px" }}
                      />
                    </div>
                  </div>
                )}
            </div>
          </div>
          {lastNode &&
            (filesOfFolder && filesOfFolder.length > 0 ? (
              <div>
                <div className="content mt-3">
                  <div className="table-main">
                    <Table responsive bordered className="table table-bordered">
                      <thead className="service-table-head">
                        <tr>
                          <th>#</th>
                          <th>Name</th>
                          <th>File Path</th>

                          <th>Uploaded By</th>
                          <th>Discipline</th>
                          <th>Date</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filesOfFolder.map((each) => {
                          return (
                            <tr>
                              <td>{each?.sNo}</td>
                              <td
                                onClick={() =>
                                  each?.documentId &&
                                  navigate({
                                    pathname: "/documents/document-details",
                                    search: `?&documentId=${each?.documentId}`,
                                  })
                                }
                                style={{
                                  cursor: `${
                                    each.documentId ? "pointer" : ""
                                  }"`,
                                  textTransform: "capitalize",
                                  fontWeight: "500",
                                  color: "#E65C4F",
                                }}
                              >
                                {each?.document?.documentName ||
                                  each?.project?.name}
                              </td>
                              <td>{each?.filePath || "-"}</td>
                              <td>{each?.document?.author?.empName || "-"}</td>
                              <td>
                                {each?.document?.selectedDiscipline?.deptName ||
                                  "-"}
                              </td>
                              <td>
                                {each?.createdAt
                                  ? moment(each?.createdAt).format(
                                    "DD-MM-YYYY hh:mm A"
                                  )
                                  : ""}
                              </td>
                              <td>
                                {each?.idcDocument && (
                                  <span
                                    style={{ color: "red" }}
                                    role="button"
                                    onClick={() =>
                                      window.open(
                                        config.documentPath + each.filePath,
                                        "_blank"
                                      )
                                    }
                                  >
                                    IDC
                                  </span>
                                )}
                                {each?.pdoCommand && (
                                  <span
                                    style={{ color: "red" }}
                                    role="button"
                                    onClick={() =>
                                      window.open(
                                        config.documentPath + each.filePath,
                                        "_blank"
                                      )
                                    }
                                  >
                                    PDO
                                  </span>
                                )}
                                {each?.jc && (
                                  <span
                                    style={{ color: "red" }}
                                    role="button"
                                    onClick={() =>
                                      window.open(
                                        config.documentPath + each.filePath,
                                        "_blank"
                                      )
                                    }
                                  >
                                    JC
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                    <div class="custom-pagination-main">
                      <div class="card-inner">
                        <Pagination
                          onNext={(page) => {
                            getFilesofFolder(selectedPath, page);
                            setPage(page);
                          }}
                          onPageClick={(page) => {
                            getFilesofFolder(selectedPath, page);
                            setPage(page);
                          }}
                          onPrev={(page) => {
                            getFilesofFolder(selectedPath, page);
                            setPage(page);
                          }}
                          currentPage={page}
                          rowsPerPage={20}
                          totalCount={documentsCount}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p className="text-center font-weight-bold">No Documents Found</p>
            ))}
        </div>
      )}

      <Modal
        centered
        show={documentModel}
        onHide={() => onDocumentModel("")}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            <div className="headerName">Upload Document</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <Formik
            initialValues={{
              uploadFile: "",
              documentName: "",
              documentNumber: "",
              category: "",
              documentType: "",
            }}
            onSubmit={(values) => {
              uploadDocument(values);
            }}
            validationSchema={documentInfoSchema}
          >
            {({
              values,
              setFieldValue,
              handleBlur,
              errors,
              touched,
              handleSubmit,
            }) => (
              <div>
                <div className="row align-items-center form-group mb-3">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <Label>Upload document</Label>
                  </div>
                  <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                    <input
                      placeholder="Enter Version"
                      onChange={(e) => {
                        let file = e.target.files[0];

                        setFieldValue("uploadFile", file);
                        let fileName = file.name;
                        let docNumber = fileName.split(".")[0];
                        setFieldValue("documentNumber", docNumber);
                        setFieldValue("documentName", docNumber);
                      }}
                      onBlur={handleBlur}
                      type="file"
                      name="fileInput"
                      id="fileInput"
                      hidden
                      ref={uploadRef}
                    />
                    <Button
                      onClick={() => {
                        uploadRef.current.click();
                      }}
                      color="primary"
                    >
                      Select File
                    </Button>
                    {values.uploadFile && <p>{values.uploadFile.name}</p>}
                    <CustomErrorMessage
                      name="uploadFile"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                {isVendor ? (
                  <div className="row align-items-center form-group mb-3">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                      <Label>Vendor Document Type</Label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                      <AsyncSelect
                        className="sop-custom-form-select"
                        id="documentType"
                        name="documentType"
                        placeholder="Select document Type"
                        onChange={(e) => {
                          setFieldValue("documentType", e);
                        }}
                        value={values.documentType}
                        onBlur={handleBlur}
                        loadOptions={(input, callback) =>
                          loadVendorDebounceOptions(input, callback)
                        }
                        defaultOptions={vendorDocTypes}
                        cacheOptions
                      />
                      <CustomErrorMessage
                        name="documentType"
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="row align-items-center form-group mb-3">
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                      <Label>Document Type</Label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                      <AsyncSelect
                        className="sop-custom-form-select"
                        id="documentType"
                        name="documentType"
                        placeholder="Select document Type"
                        onChange={(e) => {
                          setFieldValue("documentType", e);
                        }}
                        value={values.documentType}
                        onBlur={handleBlur}
                        loadOptions={(input, callback) =>
                          loadDebounceOptions(input, callback, "doc_type")
                        }
                        defaultOptions={types}
                        cacheOptions
                      />
                      <CustomErrorMessage
                        name="documentType"
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  </div>
                )}
                <div className="row align-items-center form-group mb-3">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <Label>Category</Label>
                  </div>
                  <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                    <AsyncSelect
                      className="sop-custom-form-select"
                      id="category"
                      name="category"
                      placeholder="Select Category"
                      onChange={(e) => {
                        setFieldValue("category", e);
                      }}
                      value={values.category}
                      onBlur={handleBlur}
                      loadOptions={(input, callback) =>
                        loadDebounceOptions(input, callback, "category")
                      }
                      defaultOptions={categories}
                      cacheOptions
                    />
                    <CustomErrorMessage
                      name="category"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <div className="d-flex gap-2 btn-export">
                    <Button
                      className="btn btn-light"
                      onClick={() => onDocumentModel("")}
                    >
                      <span>
                        <img src={Backicon} />
                      </span>
                      Cancel
                    </Button>
                    <Button
                      className="btn theme-btn-main"
                      onClick={handleSubmit}
                      disabled={buttonLoading}
                    >
                      {" "}
                      {buttonLoading ? "Loading..." : "Update"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={addFolderClick}
        onHide={() => onAddFolderClick("")}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            <div className="headerName">Add Folder</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <Formik
            initialValues={{
              name: "",
            }}
            onSubmit={(values) => {
              addFolder(values);
            }}
            validationSchema={addFolderSchema}
          >
            {({
              values,
              setFieldValue,
              handleBlur,
              errors,
              touched,
              handleSubmit,
              handleChange,
            }) => (
              <div>
                <div className="row align-items-center form-group mb-3">
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <Label>Name</Label>
                  </div>
                  <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                    <PrimaryInput
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      name="name"
                      id="name"
                      placeholder="Enter Folder Name"
                    />
                    <CustomErrorMessage
                      name="name"
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <div className="d-flex gap-2 btn-export">
                    <Button
                      className="btn btn-light"
                      onClick={() => onAddFolderClick("")}
                    >
                      <span>
                        <img src={Backicon} />
                      </span>
                      Cancel
                    </Button>
                    <Button
                      className="btn theme-btn-main"
                      onClick={handleSubmit}
                      disabled={buttonLoading}
                    >
                      {" "}
                      {buttonLoading ? "Loading..." : "Save"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={deleteFolderClick}
        onHide={() => onDeleteFolderClick("")}
        className="add-department-modal-main"
      >
        <Modal.Header closeButton className="add-department-modal-header">
          <Modal.Title className="add-department-modal-title">
            <div className="headerName">Delete Folder</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-department-modal-body">
          <div>{`Are You Sure You Want to Delete ${selectedFolder?.name} Folder`}</div>
          <div className="mt-3">
            <div className="d-flex gap-2 btn-export">
              <Button
                className="btn btn-light"
                onClick={() => onDeleteFolderClick("")}
              >
                <span>
                  <img src={Backicon} />
                </span>
                Cancel
              </Button>
              <Button
                className="btn theme-btn-main"
                onClick={deleteFolder}
                disabled={buttonLoading}
              >
                {" "}
                {buttonLoading ? "Loading..." : "Save"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
