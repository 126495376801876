let apiServices = {
  //file_upload apis
  uploadFile: "file",

  //User Login
  userSignin: "login",
  userGoogleSignin: "auth/google",
  userFacebookSignin: "auth/facebook",
  getUserDetails: "get-user-details-by-token",
  resetpassword: "employe-reset-password",
  getUserNotifications: "notifications-by-user-Id",
  updateNotificationReadStatus: "notifications-read-status",

  //Roles
  getRoles:'get-role',
  getFolderPermissions:'permission',
  getFolderRolePermission:'folder-role-permissions',
  updateFolderRolePermissions:'folder-role-permissions-update',


  //Resource Cost
  getResourceCost: 'resource-cost',
  addResourceCost: 'resource-cost',
  updateResourceCost: 'resource-cost',
  //RolesPermissionByRole
  getRolePermissionsByRoles: "role-user-permission",

  //employees
  getSeniorEmployees: "get-senior-employees-by-department",
  getEngineers: "get-engineers-by-department",
  getProjectEngineers: "get-project-engineers",
  getEngineeringManagers: "get-engineering-managers",

  //job Circular
  getJobCircularByProjectId: 'job-circular',
  addJobCircularByProjectId: 'job-circular',
  updateJobCircularStatus:'job-circular-update-status',
  updateJobCircularByProjectId:'job-circular',
  //CTR Masters API
  getCTRMasters: "master-ctr",
  addCTRMasters: "master-ctr",
  updateCTRMaster: "master-ctr",
  deleteCTRMaster: "master-ctr-status-update",
  importMasterCTR: 'master-ctr/upload',
  getCTRMastersRevisions: 'master-ctr-history',

  //CTD Masters API
  getMasterCTD: 'cost-time-deliverable',
  addMasterCTD: 'cost-time-deliverable',
  updateMasterCTD: 'cost-time-deliverable',
  deleteMasterCTD: 'cost-time-deliverable-update-status',
  importMasterCTD: 'master-ctd-upload',
  getRevisionCTD: 'cost-time-deliverable-history',
  getDesignationMasters:"designation",
  

  // PLIP API
  getPLIP: 'plip',
  updatePLIP: 'plip',
  importPLIP: 'plip-upload',
  deletePLIP: 'plip-status-update',
  exportPlip:"plip-export",

  //IDC Masters API
  getIDCMasters: "workflow/master",
  AddIDCMasters: "workflow/master",
  deleteIDCMaster: "workflow/master",
  updateIDCMaster: "workflow/master",

  getIdcDeptsandEmployees:"reviwers-dept-by-ctr",
  getIdcMatrix:"idc",
  importIdc:"idc-matrix/upload",
  updateIdcMatrix:"idc",

  //Project CTR APIs
  getProjectCTR: "ctr_deliverable",
  addProjectCTR: "ctr_deliverable",
  updateProjectCTR: "ctr_deliverable",
  deleteProjectCTR: "ctr_deliverable-status-update",
  importProjectCTR: "file_upload",
  importProjectCTRById: "ctr-deliverable/upload",
  exportProjectCTR: 'project/export',
  getRevisionProjectCTR: 'ctr-deliverable-history',
  progressReportDownload: 'engineering-progress',
  ddrGeneration:"ddr-auto-generation-by-ctr",


  //Project CTD APIs
  getProjectCTD: 'project-ctd',
  addProjectCTD: 'project-ctd',
  deleteProjectCTD: 'project-ctd-status-update',
  updateProjectCTD: 'project-ctd',
  importProjectCTDById: 'project-ctd-upload',
  getRevisionProjectCTD: 'project-ctd-history',

  // All Master Components Export APIs
  masterCtrExportAll: 'master-ctr-export-all',
  engineeringProgressSummary: 'engineering-progress-summary',
  documentCategoryExport: 'document-category-export',
  docTypeExport: 'doc-type-export',
  masterCtrActivityExport: 'master-ctr-activity-export',
  masterCtdExport: 'master-ctd-export',
  checkListItemExport: 'check-list-item-export',
  masterIdcExport: 'idc-export',
  engineeringProgressOverallProgressReport: 'engineering-progress-overall-progress-report',

  //Queue Status
  getQueueStatus: 'queue-status',

  //Departments
  getDepartmentsMaster: "deptartment",

  //Departments
  getEmployeesMaster: "employe",

  //Project Summary apis
  getProjectHourSummary: 'project-hours-summary',
  getProjectResourceSummary: 'project-resource-summary',
  getProjectCostSummary: 'project-cost-summary',
  getProjectHourSummaryForCharts:'project-hours-summary-for-charts',
  getProjectCostSummaryForCharts:'project-cost-summary-for-charts',
  getProjectResourceSummaryForCharts:'project-resource-summary-for-charts',

  //CTR Activity
  getCTRActivityMasters: 'master-ctr-activity',
  addCTRActivityMaster: 'master-ctr-activity',
  updateCTRActivityMaster: 'master-ctr-activity',
  deleteCTRActivityMaster: 'master-ctr-activity-status-update',
  importCTRActivity: 'master-ctr-activity-upload',
  getRevisionCTRActivity: 'master-ctr-activity',
  //TPS List
  getTPSMaster: 'master-tps',
  addTPSMaster: 'master-tps',
  updateTPSMaster: 'master-tps',
  deleteTPSMaster: 'master-tps-status-update',
  importTPS: 'master-tps-upload',
  getRevisionTPS: 'master-tps',

  //project TPS
  getProjectTPS: 'project-tps',
  addProjectTPS: 'project-tps',
  updateProjectTPS: 'project-tps',
  deleteProjectTPS: 'project-tps-status-update',
  importProjectTPS: '',

  //Project CTR Activity
  getProjectCTRActivity: 'project-ctr-activity',
  addProjectCTRActivity: 'project-ctr-activity',
  updateProjectCTRActivity: 'project-ctr-activity',
  deleteProjectCTRActivity: 'project-ctr-activity-status-update',
  importProjectCTRActivity: 'project-ctr-activity-upload',
  getRevisionProjectCTRActivity: 'project-ctr-activity-history',
  // Projects apis
  projectMasters: "project",
  deleteProjectMaster: "project-status-update",
  getProjectDetails: "project",
  documentStatsByProjectId:'document-stags',
  //resource pool apis
  getResourcePools: "res_pool",
  getResourcePoolsByDeptId: "res_pool",

  //folder
  addFolderByName:'new-project-folder',
  deleteFolderByName:'delete-project-folder',

  //documents
  getDocuments: "documents",
  getSelfDocMasters: "document-self",
  document: "document",
  updateDocumentStatus: "document-status-update",
  updateDocumentVersion: "document-version",
  statusUpdate: "document-status",
  getToolsAccess: "tool-access",
  getReviewsByUser: "review-dashboard",
  getReviewsList:'review-dashboard-getList',
  getTotalReviews:'review-dashboard-all',
  getIDCPending:'idc-pending',
  updateReviewStatus: "review-dashboard-change-status",
  pdoComment: "pdo-comments",
  downloadDocumentIdc: "workflow/master/export",
  downloadDocumentIdcPdf: "idc-pdf-export",
  downloadDocumentDdr: "document/export",
  getDwgFileBuffer: "get-file-buffer",
  importDDR:'ddr-upload',
  documentStampUpdate:"document-stamp",
  documentStampByReviewer:"document-stamp-by-reviewer",
  getAllDocuments:"get-documents",


  eventWorkflow: "event-workflow",
  updateEventWorkFlowDueDate:'event-workflow',
  updateEventWorkflowStatus: "event-workflow-status-update",
  workflowComment: "workflow-comments",
  updateWorkFlowCommentStatus: "workflow-comments-status-update",
  uploadCommentFiles: "file_upload",
  addWorkflowUsers:"add-event-workflow-users",

  //technical comments
  technicalComments: "technical-comments",
  updateTechnicalCommentsStatus: "technical-comments-status-update",

  //get folderStructure
  getFolderStructure: "get-project-structure",
  getNewFolderStructure:'list-project-folder',
  uploadProjectDocument: "upload-project-document",

  //departments/discipline
  getDepartmentMasters: "deptartment",
  getDocumentCount: "document-count",
  //Doctype atart
  doctypeMasters: "doc-type",
  deleteDoctypeMaster: "doc-type-status-update",

  //checklist apis
  checkListMasters: "check-list-master",
  deleteCheckListMaster: "check-list-master-status-update",
  getCheckListDetails: "check-list-master",

  CheckListItemMasters: "check-list-item",
  deleteCheckListItemMaster: "check-list-item-status-update",
  importCheckListItems:"checklist-item/upload",

  //Document category
  getDocumentCategoryMasters: "document-category",
  addDocumentCategoryMaster: "document-category",
  updateDocumentCategoryMaster: "document-category",
  deleteDocumentCategoryMaster: "document-category-status-update",
  assignBackCheck: "document-assign-for-review",

  //getCheck list item get
  getCheckListItemChoose: "get-list-with-check-list-item",

  //document-tagging
  documentFolderTagging: "document-folder-tagging",

  getDocumentHistory: 'audit-trail',


  getVendorDocTypes:"vendor-document"
};
export default apiServices;
