import "./Sidebar.scss";
import { NavLink as RouterLink } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { icons } from "../../assets/images/icons/icons";
import logo from "../../assets/images/icons/logo.svg";
import ArizonaLogo from "../../assets/images/icons/arizonaLogo.svg";
import { Button } from "reactstrap";
import axios from "axios";
import { addicon } from "../../assets/images/icons/icons";
import { sidebarbottomlogo } from "../../assets/images/icons/icons";
import usePermission from "../../hooks/usePermission";
import PermissionFunctions from "../../assets/functions/PermissionFunctions";
import MainServices from "../../services/MainServices";
import useConfig from "../../hooks/useConfig";

export default function Sidebar({ setSidebarToggle }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [menuToggle, setMenuToggle] = useState({});
  const [menus, setMenus] = useState([]);
  const { grantPermission } = PermissionFunctions();
  const [applications, setApplications] = useState([]);
  const { getToolsAccess } = MainServices();
  const {  source, appLogo, appVersion } = useConfig();

  useEffect(() => {
    axios
      .get("menu.json")
      .then(function (res) {
        if (res?.data) {
          let resData = res.data;
          resData = resData.menus ? resData.menus : [];
          setMenus(resData);
          console.log("menu", resData);
        }
      })
      .catch(function (res) {
        console.log(res);
        console.log("An error occurred menu json");
      });
  }, []);

  const onLogout = () => {
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  useEffect(() => {
    getToolMasterByStatus();
  }, []);

  const toggle = (type) => {
    if (type === "projectManagementIcon")
      navigate({ pathname: "project-management/projects" });
    setMenuToggle({ [type]: menuToggle[type] ? false : true });
    if (type === "mastersIcon") navigate({ pathname: "masters/doctypes" });
    setMenuToggle({ [type]: menuToggle[type] ? false : true });
    if (type === "documentIcon") navigate({ pathname: "documents/list" });
    setMenuToggle({ [type]: menuToggle[type] ? false : true });

    if (type === "trainingmanagementicon")
      navigate({ pathname: "documents/registration" });
    setMenuToggle({ [type]: menuToggle[type] ? false : true });
  };

  const getToolMasterByStatus = async () => {
    const details = {
      pageSize: 20,
      pageNumber: 1,
    };
    await axios
      .all([getToolsAccess(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let location = resData.data ? resData.data : [];

            location = location.map((_, index) => {
              return {
                ..._,
              };
            });
            setApplications(location);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Location");
      });
  };

  return (
    <div className="sidebar-main">
      <div className="nk-sidebar is-light" data-content="sidebarMenu">
        <div className="nk-sidebar-element nk-sidebar-head">
          <div className="nk-sidebar-brand himsidebar-logo">
            <div className="himsidebar-logo-main">
            <img src={appLogo && appLogo !== "" ? appLogo : ArizonaLogo} alt="logo" />
            </div>
          </div>
        </div>
        <hr
          style={{
            borderBottom: "2px solid #fff",
          }}
        />
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-content">
            <SimpleBar className="nk-sidebar-menu" autoHide={false}>
              <ul className="nk-menu sidebar-option-menu-main">
                {grantPermission("ProjectDashboard_read") && (
                  <li
                  className={`nk-menu-item ${pathname.indexOf("/dashboard") > -1 ? "active" : ""}`}
                >
                  <RouterLink
                  to={"/dashboard"}
                  className={"nk-menu-link"}
                  onClick={() => setMenuToggle({})}
                >
                  <span className="nk-menu-icon">
                    {icons.dashboardicon}
                  </span>
                  <span className="nk-menu-text">Dashboard</span>
                </RouterLink>
                </li>
                )}
                {applications &&
                  applications.length > 0 &&
                  applications.map(
                    (site) =>
                      site.name !== "galfar" && (
                        <li className={`nk-menu-item`}>
                          <a
                            href={site.siteUrl}
                            className={"nk-menu-link"}
                            onClick={() => setMenuToggle({})}
                          >
                            <span className="nk-menu-icon">{icons.link}</span>
                            <span className="nk-menu-text">
                              {" "}
                              {site.name === "auth"
                                ? "Authentication"
                                : site.name === "galfar"
                                ? "Project"
                                : ""}
                            </span>
                          </a>
                        </li>
                      )
                  )}
                {menus &&
                  menus.length > 0 &&
                  menus.map((each) => {
                    let isChildren = each.children && each.children.length > 0;
                    return (
                      grantPermission(each.required_permission) && (
                        <li
                          className={`nk-menu-item ${
                            isChildren ? "has-sub " : ""
                          }${
                            isChildren && !menuToggle[each.icon]
                              ? "menu-sub-collapse "
                              : ""
                          }${pathname.indexOf(each.link) > -1 ? "active" : ""}`}
                        >
                          {each.children && each.children.length > 0 ? (
                            <>
                              <span
                                className="nk-menu-link nk-menu-toggle span-toggle"
                                onClick={() => toggle(each.icon)}
                              >
                                <span className="nk-menu-icon">
                                  {icons[each.icon]}
                                </span>
                                <span className="nk-menu-text">
                                  {each.label}
                                </span>
                              </span>

                              <ul
                                className="nk-menu-sub"
                                style={{
                                  display: menuToggle[each.icon]
                                    ? "block"
                                    : "none",
                                }}
                              >
                                {each.children.map((_) => {
                                  return (
                                    grantPermission(_.required_permission) && (
                                      <li
                                        className={`nk-menu-item ${
                                          pathname === each.link + _.link
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <RouterLink
                                          to={{
                                            pathname: each.link + _.link,
                                          }}
                                          className="nk-menu-link"
                                        >
                                          <span className="nk-menu-text">
                                            {_.label}
                                          </span>
                                        </RouterLink>
                                      </li>
                                    )
                                  );
                                })}
                              </ul>
                            </>
                          ) : (
                            <RouterLink
                              to={each.link}
                              className={"nk-menu-link"}
                              onClick={() => setMenuToggle({})}
                            >
                              <span className="nk-menu-icon">
                                {icons[each.icon]}
                              </span>
                              <span className="nk-menu-text">{each.label}</span>
                            </RouterLink>
                          )}
                        </li>
                      )
                    );
                  })}

                <hr
                  style={{
                    borderBottom: "2px solid #fff",
                  }}
                />
                {/* <Button
                  className="sidebar-addicon-btn-main"
                  onClick={() => navigate("/schedules/all-trainings")}
                >
                  <span className="sidebarbtn-with-icon-title">
                    Schedule Training
                  </span>
                  <span>{addicon}</span>
                </Button> */}

                <div className="mx-3 d-flex justify-content-center">
                  {sidebarbottomlogo}
                </div>
                <li>
                  <div className="m-0 d-flex justify-content-center">
                    <span className="sidebarbtn-with-icon-title text-white" 
                      style={{ 
                        fontSize: "11px",
                        fontFamily: "Montserrat"
                      }}
                    >
                      {`Version : ${appVersion || "0"}`}
                    </span>
                  </div>
                </li>
              </ul>
            </SimpleBar>
          </div>
        </div>
      </div>
    </div>
  );
}
