import React, { useEffect, useState } from "react";
import "./AddProject.scss";
import useCurrentPage from "../../../hooks/useCurrentPage";
import { Form, Formik } from "formik";
import CustomErrorMessage from "../../../assets/elements/ErrorMessage";
import { Label, Button } from "reactstrap";
import { PrimaryInput } from "../../../assets/FormElements";
import { PrimaryButton } from "../../../assets/buttons";
import ButtonLoading from "../../../assets/loaders/ButtonLoading";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import TickSquare from "../../../assets/images/icons/TickSquare.svg";
import CloseGreyIcon from "../../../assets/images/icons/close-grey-icon.svg";
import NextWhiteIcon from "../../../assets/images/icons/next-icon-white.svg";
import axios from "axios";
import MainServices from "../../../services/MainServices";
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import moment from "moment";
import useAuth from "../../../hooks/useAuth";

export default function AddProject() {
  const { updateCurrentPage } = useCurrentPage();
  const [addDetailsTabs, setAddDetailsTabs] = useState("project-info");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const navigate = useNavigate();
  const {
    addProjectMaster,
    getProjectMasters,
    updateProjectMaster,
    importProjectCTR,
    getProjectEngineers,
  } = MainServices();
  const location = useLocation();
  const projectId = new URLSearchParams(location.search).get("projectId");
  const { user } = useAuth();
  const [projectEngineers, setProjectEngineers] = useState([]);
  const [projectType, setProjectType] = useState("");

  useEffect(() => {
    updateCurrentPage({
      title: "Project",
      subtitle: "Add Project",
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectId) getProjectDetials();
  }, [projectId]);

  useEffect(() => {
    getEngineers();
  }, []);

  //functions
  const onTabChange = ({ type, setErrors, setTouched, values }) => {
    let validate = onStepValidation(type, setErrors, setTouched, values);
    console.log(validate);
    if (validate) {
      setAddDetailsTabs(type);
      setErrors({});
      setTouched({});
    }
  };

  const onStepValidation = (type, setErrors, setTouched, values) => {
    try {
      projectInfoSchema.validateSync(values, { abortEarly: false });
      return true;
    } catch (errors) {
      console.log(errors);
      let vErrors = {};
      let vTouched = {};
      errors.inner.forEach((error) => {
        vErrors[error.path] = error.message;
        vTouched[error.path] = true;
      });
      setTouched(vTouched);
      setErrors(vErrors);
      return false;
    }
  };

  const onSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  //validation
  const nonStandardProjectInfoSchema = Yup.object().shape({
    projectName: Yup.string()
      .test(
        "not-only-whitespace",
        " Project Name  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Project Name is Required"),
    refIndicator: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("PDO PE Name/Ref Indicator is Required"),
    estimate: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Estimate/CTR Code is Required"),
    ctrRevisionNo: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("CTR Revision Number is Required"),
    wbsNo: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("WBS Number is Required"),
    engineeringScope: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Engineering Scope is Required"),
    estimateReq: Yup.string().required("Date (Estimate Request) is Required"),
    estimateSub: Yup.string().required(
      "Date (Estimate Submission) is Required"
    ),
    approvedSub: Yup.string().required(
      "Date (Approved Submission) is Required"
    ),
    engineeringPo: Yup.string().required("Date (Engineering PO) is Required"),
    //fileName: Yup.string().required("CTR upload is Required"),
    projectType: Yup.string().required("Project Type is Required"),
    projectEngineer: Yup.string().required("Project Engineer is Required"),
    ramzProjectId: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Ramz Project id is Required"),
  });
  const standardProjectInfoSchema = Yup.object().shape({
    projectName: Yup.string()
      .test(
        "not-only-whitespace",
        " Project Name  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Project Name is Required"),
    refIndicator: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("PDO PE Name/Ref Indicator is Required"),
    estimate: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Estimate/CTR Code is Required"),
    ctrRevisionNo: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("CTR Revision Number is Required"),
    wbsNo: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("WBS Number is Required"),
    engineeringScope: Yup.string() .test(
      "not-only-whitespace",
      " Field  cannot be empty or only spaces",
      (value) => value && value.trim().length > 0
    ).required("Engineering Scope is Required"),
    projectType: Yup.string().required("Project Type is Required"),
    projectSubType: Yup.string().required("Project Sub Type is Required"),
    projectEngineer: Yup.string().required("Project Engineer is Required"),
    ramzProjectId: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Ramz Project id is Required"),
  });
  const projectInfoSchema = Yup.object().shape({
    projectName: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Project Name is Required"),
    refIndicator: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("PDO PE Name/Ref Indicator is Required"),
    estimate: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Estimate/CTR Code is Required"),
    ctrRevisionNo: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("CTR Revision Number is Required"),
    wbsNo: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("WBS Number is Required"),
    engineeringScope: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Engineering Scope is Required"),
    projectType: Yup.string().required("Project Type is Required"),
    projectEngineer: Yup.string().required("Project Engineer is Required"),
    ramzProjectId: Yup.string()
      .test(
        "not-only-whitespace",
        " Field  cannot be empty or only spaces",
        (value) => value && value.trim().length > 0
      )
      .required("Ramz Project id is Required"),
  });

  const projectInfoSchemaUpdate = Yup.object().shape({
    projectName: Yup.string().required("Project Name is Required"),
    refIndicator: Yup.string().required(
      "PDO PE Name/Ref Indicator is Required"
    ),
    estimate: Yup.string().required("Estimate/CTR Code is Required"),
    ctrRevisionNo: Yup.string().required("CTR Revision Number is Required"),
    wbsNo: Yup.string().required("WBS Number is Required"),
    engineeringScope: Yup.string().required("Engineering Scope is Required"),
    estimateReq: Yup.string().required("Date (Estimate Request) is Required"),
    estimateSub: Yup.string().required(
      "Date (Estimate Submission) is Required"
    ),
    approvedSub: Yup.string().required(
      "Date (Approved Submission) is Required"
    ),
    engineeringPo: Yup.string().required("Date (Engineering PO) is Required"),
    projectType: Yup.string().required("Project Type is Required"),
    projectEngineer: Yup.string().required("Project Engineer is Required"),
    ramzProjectId: Yup.string().required("Ramz Project id is Required"),
  });

  // const highLevelScopeSchema = Yup.object().shape({
  //   processEqu: Yup.string().required("Process Equipment is Required"),
  //   uniqueEqu: Yup.string().required("Unique Equipment is Required"),
  //   concrete: Yup.string().required("Concrete is Required"),
  //   steel: Yup.string().required("Steel is Required"),
  //   ioList: Yup.string().required("I/o List is Required"),
  //   electricalInst: Yup.string().required(
  //     "Electrical Installations is Required"
  //   ),
  //   pipeLm: Yup.string().required("Pipe (LM) is Required"),
  //   pipeTonne: Yup.string().required("Pipe (Tonne) is Required"),
  //   mechUiEqu: Yup.string().required(
  //     "Mechanical Unique Equipments is Required"
  //   ),
  // });

  // apis
  const getProjectDetials = async () => {
    setPageLoading(true);
    const details = {
      filters: [
        {
          key: "publicId",
          eq: projectId,
        },
      ],
    };
    await axios
      .all([getProjectMasters(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let projectDetails = resData.data ? resData.data : [];
            projectDetails = projectDetails[0] ? projectDetails[0] : {};
            projectDetails = {
              ...projectDetails,
              estimateRequest: projectDetails?.estimateRequest?.slice(0, 10),
              initialEngineeringSubmission:
                projectDetails?.initialEngineeringSubmission?.slice(0, 10),
              approvedEngineeringSubmission:
                projectDetails?.approvedEngineeringSubmission?.slice(0, 10),
              engineeringPo: projectDetails?.engineeringPo?.slice(0, 10),
            };
            console.log(projectDetails);
            setSelectedProject(projectDetails);
            setPageLoading(false);
          } else {
            if (message) notifyWarn(message);
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Project Details");
      });
  };

  const uploadFile = (values) => {
    setButtonLoading(true);
    if (values.fileName && typeof values.fileName !== "string") {
      let documents = values.fileName;
      documents.field = "fileName";
      let docs = [documents];
      axios
        .all([importProjectCTR(docs, user?.token)])
        .then(function (res) {
          if (res) {
            let resData = res[0];
            if (resData.success) {
              let fileData = resData.data;
              fileData = fileData?.doc;
              let files = {};
              fileData.map((eachFile) => {
                files.fileName = eachFile.filename;
                return true;
              });
              addProject(values, files);
            } else {
              setButtonLoading(false);
            }
          }
        })
        .catch(function (res) {
          if (res.message) console.log("An error occurred in file upload");
        });
    } else {
      addProject(values);
    }
  };

  const addProject = async (values, files) => {
    setButtonLoading(true);
    const details = {
      name: values?.projectName,
      pdoPeName: values?.refIndicator,
      ctrCode: values?.estimate,
      ctrRevisionNo: values?.ctrRevisionNo,
      wbsNo: values?.wbsNo,
      engineeringScope: values?.engineeringScope,
      ...(values.projectType === "non standard" && {
        estimateRequest: moment(values?.estimateReq).format(),
        initialEngineeringSubmission: moment(values?.estimateSub).format(),
        approvedEngineeringSubmission: moment(values?.approvedSub).format(),
        engineeringPo: moment(values?.engineeringPo).format(),
      }),

      filePath: files?.fileName,
      projectType: values.projectType,
      ...(values.projectType === "standard" && {
        projectSubType: values?.projectSubType,
      }),
      ramzProjectId: values.ramzProjectId,
      projectEngineer: values.projectEngineer,

      // totalProcessEquipment: values?.processEqu,
      // totalUniqueEquipment: values?.uniqueEqu,
      // concrete: values?.concrete,
      // steel: values?.steel,
      // controlAndAutomationList: values?.ioList,
      // electricalInstallation: values?.electricalInst,
      // pipeLm: values?.pipeLm,
      // pipeTonne: values?.pipeTonne,
      // mechanicalUniqueEquipments: values?.mechUiEqu,
    };
    await axios
      .all([addProjectMaster(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          console.log(resData);

          if (resData.success) {
            notifySuccess("Project Master Added Successfully");
            onSuccessModal();
            setButtonLoading(false);
            navigate("/project-management/projects");
          } else {
            let details = resData.details || [];
            if (details[0]) {
              notifyWarn(details[0].message);
            }
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in adding Project Master");
      });
  };

  const updateProject = async (values) => {
    setButtonLoading(true);
    const details = {
      id: selectedProject?.id,
      name: values?.projectName,
      pdoPeName: values?.refIndicator,
      ctrCode: values?.estimate,
      ctrRevisionNo: values?.ctrRevisionNo,
      wbsNo: values?.wbsNo,
      engineeringScope: values?.engineeringScope,
      ...(values.projectType === "non standard" && {
        estimateRequest: moment(values?.estimateReq).format(),
        initialEngineeringSubmission: moment(values?.estimateSub).format(),
        approvedEngineeringSubmission: moment(values?.approvedSub).format(),
        engineeringPo: moment(values?.engineeringPo).format(),
      }),
      projectType: values.projectType,
      ...(values.projectType === "standard" && {
        projectSubType: values?.projectSubType,
      }),
      ramzProjectId: values.ramzProjectId,
      projectEngineer: values.projectEngineer,
      // totalProcessEquipment: values?.processEqu,
      // totalUniqueEquipment: values?.uniqueEqu,
      // concrete: values?.concrete,
      // steel: values?.steel,
      // controlAndAutomationList: values?.ioList,
      // electricalInstallation: values?.electricalInst,
      // pipeLm: values?.pipeLm,
      // pipeTonne: values?.pipeTonne,
      // mechanicalUniqueEquipments: values?.mechUiEqu,
      status: "active",
    };
    await axios
      .all([
        updateProjectMaster(
          details,
          selectedProject.publicId,
          selectedProject.concurrencyStamp
        ),
      ])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            notifySuccess("Project Master Updated Successfully");
            onSuccessModal();
            setButtonLoading(false);
            navigate("/project-management/projects");
          } else {
            setButtonLoading(false);
          }
        } else {
          setButtonLoading(false);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in updating Project Master");
      });
  };

  const getEngineers = async () => {
    const details = {};
    await axios
      .all([getProjectEngineers(details)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let employees = resData?.data;
            employees = employees.map((each) => {
              return {
                ...each,
                label: each?.empName,
                value: each?.publicId,
              };
            });
            setProjectEngineers(employees);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in getting Branches");
      });
  };

  const downloadSampleProjectCTR = (filePath) => {
    const link = document.createElement("a");
    link.href =
      "https://files.appsarizona.com/galfar-apis/server/exports/demo/CTR-Deliverable.xlsx";
    link.setAttribute("download", "sample_ctr_deliverable.xlsx"); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
  };

  return (
    <div className="add-project-main">
      {pageLoading ? (
        <pageLoading />
      ) : (
        <>
          <div>
            <Formik
              initialValues={{
                projectName: selectedProject?.name || "",
                refIndicator: selectedProject?.pdoPeName || "",
                estimate: selectedProject?.ctrCode || "",
                ctrRevisionNo: selectedProject?.ctrRevisionNo || "",
                wbsNo: selectedProject?.wbsNo || "",
                engineeringScope: selectedProject?.engineeringScope || "",
                estimateReq: selectedProject?.estimateRequest || "",
                estimateSub:
                  selectedProject?.initialEngineeringSubmission || "",
                approvedSub:
                  selectedProject?.approvedEngineeringSubmission || "",
                engineeringPo: selectedProject?.engineeringPo || "",
                fileName: selectedProject?.fileName || "",
                projectType: selectedProject?.projectType || "",
                projectSubType: selectedProject?.projectSubType || "",
                ramzProjectId: selectedProject?.ramzProjectId || "",
                projectEngineer: selectedProject?.projectEngineer || "",
                // processEqu: selectedProject?.totalProcessEquipment || "",
                // uniqueEqu: selectedProject?.totalUniqueEquipment || "",
                // concrete: selectedProject?.concrete || "",
                // steel: selectedProject?.steel || "",
                // ioList: selectedProject?.controlAndAutomationList || "",
                // electricalInst: selectedProject?.electricalInstallation || "",
                // pipeLm: selectedProject?.pipeLm || "",
                // pipeTonne: selectedProject?.pipeTonne || "",
                // mechUiEqu: selectedProject?.mechanicalUniqueEquipments || "",
              }}
              // validationSchema={projectValidationSchema}
              validationSchema={
                projectType === "non standard"
                  ? nonStandardProjectInfoSchema
                  : projectType === "standard"
                  ? standardProjectInfoSchema
                  : projectInfoSchema
              }
              onSubmit={(values) => {
                selectedProject ? updateProject(values) : uploadFile(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setErrors,
                setTouched,
                setFieldValue,
              }) => (
                <div>
                  <div className="add-project-card-main">
                    <div>
                      <h1 className="add-project-card-header-main mb-1">
                        {projectId ? "Update" : "Create"} Project
                      </h1>
                      <p className="m-0 add-project-card-para-main">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout.
                      </p>
                    </div>
                    <div className="add-project-tab-main">
                      <div
                        className={`add-project-tabs ${
                          addDetailsTabs === "project-info" ? "active" : ""
                        }`}
                        onClick={() =>
                          onTabChange({
                            type: "project-info",
                            setErrors,
                            setTouched,
                            values,
                          })
                        }
                      >
                        <p className="m-0">Project Info</p>
                      </div>
                      {/* <div
                        className={`add-project-tabs ${
                          addDetailsTabs === "high-level-scope" ? "active" : ""
                        }`}
                      >
                        <p
                          className="m-0"
                          onClick={() =>
                            onTabChange({
                              type: "high-level-scope",
                              setErrors,
                              setTouched,
                              values,
                            })
                          }
                        >
                          High Level Scope Quantitites
                        </p>
                      </div> */}
                    </div>
                  </div>

                  <div className="add-project-card-main mt-3">
                    <Form className="form" onSubmit={handleSubmit}>
                      {addDetailsTabs === "project-info" && (
                        <>
                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Project Name</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter Project Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.projectName}
                                name="projectName"
                                id="projectName"
                                style={
                                  selectedProject && values.projectName && values.projectName !== "" 
                                    ? { cursor: "not-allowed", backgroundColor: "#e9ecef", fontWeight: "bold" } 
                                    : undefined
                                }
                                disabled={!!(selectedProject && values.projectName && values.projectName !== "")}                                
                              />
                              <CustomErrorMessage
                                name="projectName"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>PDO PE Name/Ref Indicator</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter PDO PE Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.refIndicator}
                                name="refIndicator"
                                id="refIndicator"
                              />
                              <CustomErrorMessage
                                name="refIndicator"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Estimate/CTR Code</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter CTR Code"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.estimate}
                                name="estimate"
                                id="estimate"
                              />
                              <CustomErrorMessage
                                name="estimate"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>CTR Revision Number</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="number"
                                placeholder="Enter CTR Revision Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ctrRevisionNo}
                                name="ctrRevisionNo"
                                id="ctrRevisionNo"
                              />
                              <CustomErrorMessage
                                name="ctrRevisionNo"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>WBS Number</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter WBS Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.wbsNo}
                                name="wbsNo"
                                id="wbsNo"
                              />
                              <CustomErrorMessage
                                name="wbsNo"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>
                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Project Type</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <select
                                type="text"
                                placeholder="Enter Project Id "
                                onChange={(e) => {
                                  handleChange(e);
                                  setProjectType(e.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.projectType}
                                name="projectType"
                                id="projectType"
                                className="form-select"
                              >
                                <option value="">Select type</option>
                                <option value="standard">Standard</option>
                                <option value="non standard">
                                  Non Standard
                                </option>
                              </select>
                              <CustomErrorMessage
                                name="projectType"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>
                          {values.projectType === "standard" && (
                            <div className="row align-items-center form-group mb-3">
                              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                <Label>Project Sub Type</Label>
                              </div>
                              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                <select
                                  type="text"
                                  placeholder="Enter Project Id "
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.projectSubType}
                                  name="projectSubType"
                                  id="projectSubType"
                                  className="form-select"
                                >
                                  <option value="">Select type</option>
                                  <option value="gas">Gas</option>
                                  <option value="oil">Oil</option>
                                  <option value="steam">Steam</option>
                                </select>
                                <CustomErrorMessage
                                  name="projectSubType"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                          )}
                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>RAMZ ProjectId</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter Project id"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ramzProjectId}
                                name="ramzProjectId"
                                id="ramzProjectId"
                              />
                              <CustomErrorMessage
                                name="ramzProjectId"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Project Engineer</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <select
                                type="text"
                                placeholder="Enter WBS Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.projectEngineer}
                                name="projectEngineer"
                                id="projectEngineer"
                                className="form-select"
                              >
                                <option value="">Select type</option>
                                {projectEngineers &&
                                  projectEngineers.length > 0 &&
                                  projectEngineers.map((each) => (
                                    <option value={each.publicId}>
                                      {each.empName}
                                    </option>
                                  ))}
                              </select>
                              <CustomErrorMessage
                                name="projectEngineer"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Engineering Scope</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter Engineering Scope"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.engineeringScope}
                                name="engineeringScope"
                                id="engineeringScope"
                              />
                              <CustomErrorMessage
                                name="engineeringScope"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          {values.projectType === "non standard" && (
                            <>
                              <div className="row align-items-center form-group mb-3">
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                  <Label>Date (Estimate Request)</Label>
                                </div>
                                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                  <PrimaryInput
                                    type="date"
                                    placeholder=""
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.estimateReq}
                                    name="estimateReq"
                                    id="estimateReq"
                                  />
                                  <CustomErrorMessage
                                    name="estimateReq"
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                              </div>

                              <div className="row align-items-center form-group mb-3">
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                  <Label>Date (Estimate Submission)</Label>
                                </div>
                                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                  <PrimaryInput
                                    type="date"
                                    placeholder=""
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.estimateSub}
                                    name="estimateSub"
                                    id="estimateSub"
                                  />
                                  <CustomErrorMessage
                                    name="estimateSub"
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                              </div>

                              <div className="row align-items-center form-group mb-3">
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                  <Label>Date (Approved Submission)</Label>
                                </div>
                                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                  <PrimaryInput
                                    type="date"
                                    placeholder=""
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.approvedSub}
                                    name="approvedSub"
                                    id="approvedSub"
                                  />
                                  <CustomErrorMessage
                                    name="approvedSub"
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                              </div>

                              <div className="row align-items-center form-group mb-3">
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                  <Label>Date (Engineering PO)</Label>
                                </div>
                                <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                  <PrimaryInput
                                    type="date"
                                    placeholder=""
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.engineeringPo}
                                    name="engineeringPo"
                                    id="engineeringPo"
                                  />
                                  <CustomErrorMessage
                                    name="engineeringPo"
                                    errors={errors}
                                    touched={touched}
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          {/* {!selectedProject && (
                            <div className="row align-items-center form-group mb-3">
                              <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                <Label>Project CTR</Label>
                              </div>
                              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                                <PrimaryInput
                                  type="file"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "fileName",
                                      e?.target?.files[0]
                                    );
                                  }}
                                  onBlur={handleBlur}
                                  accept=".xlsx,.xls"
                                  name=" fileName"
                                  id="fileName"
                                />
                                <CustomErrorMessage
                                  name="fileName"
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                            </div>
                          )} */}
                        </>
                      )}

                      {/* {addDetailsTabs === "high-level-scope" && (
                        <>
                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Process Equipment</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="number"
                                placeholder="Enter total number of process equipment's"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.processEqu}
                                name="processEqu"
                                id="processEqu"
                              />
                              <CustomErrorMessage
                                name="processEqu"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Unique Equipment</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="number"
                                placeholder="Enter total number of unique equipment's (Standby)"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.uniqueEqu}
                                name="uniqueEqu"
                                id="uniqueEqu"
                              />
                              <CustomErrorMessage
                                name="uniqueEqu"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Concrete</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter Civil & Structural concrete m3"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.concrete}
                                name="concrete"
                                id="concrete"
                              />
                              <CustomErrorMessage
                                name="concrete"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Steel</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter Civil & Structural Steel in Tonne"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.steel}
                                name="steel"
                                id="steel"
                              />
                              <CustomErrorMessage
                                name="steel"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>I/o List</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter number of I/O list of Control and Automation"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ioList}
                                name="ioList"
                                id="ioList"
                              />
                              <CustomErrorMessage
                                name="ioList"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Electrical Installations</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter electrical installation in Kilowatts"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.electricalInst}
                                name="electricalInst"
                                id="electricalInst"
                              />
                              <CustomErrorMessage
                                name="electricalInst"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Pipe (LM)</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter piping & pipeline pipe length in (lm)"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.pipeLm}
                                name="pipeLm"
                                id="pipeLm"
                              />
                              <CustomErrorMessage
                                name="pipeLm"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Pipe (Tonne)</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="text"
                                placeholder="Enter piping & pipeline pipe (tonne)"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.pipeTonne}
                                name="pipeTonne"
                                id="pipeTonne"
                              />
                              <CustomErrorMessage
                                name="pipeTonne"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>

                          <div className="row align-items-center form-group mb-3">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                              <Label>Mechanical Unique Equipments</Label>
                            </div>
                            <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                              <PrimaryInput
                                type="number"
                                placeholder="Enter total number of mechanical equipment"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mechUiEqu}
                                name="mechUiEqu"
                                id="mechUiEqu"
                              />
                              <CustomErrorMessage
                                name="mechUiEqu"
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </div>
                        </>
                      )} */}
                      {/* <div
                        className="d-flex justify-content-end"
                        style={{
                          color: "blue",
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => downloadSampleProjectCTR()}
                      >
                        Download Sample File
                      </div> */}

                      <div className="row">
                        <div className="col-4"></div>
                        <div className="mt-5 d-flex gap-2 align-items-center justify-content-end col-7">
                          {addDetailsTabs === "project-info" && (
                            <>
                              <div>
                                <Button onClick={() => navigate(-1)}>
                                  Cancel
                                </Button>
                              </div>
                              {/* <div>
                                <PrimaryButton
                                  onClick={() =>
                                    onTabChange({
                                      type: "high-level-scope",
                                      setErrors,
                                      setTouched,
                                      values,
                                    })
                                  }
                                  title="Continue"
                                />
                              </div> */}
                              <div>
                                <PrimaryButton
                                  type="submit"
                                  title={
                                    buttonLoading ? <ButtonLoading /> : "Submit"
                                  }
                                />
                              </div>
                            </>
                          )}
                          {addDetailsTabs === "high-level-scope" && (
                            <>
                              <div>
                                <Button
                                  onClick={() =>
                                    setAddDetailsTabs("project-info")
                                  }
                                >
                                  Back
                                </Button>
                              </div>
                              <div>
                                <PrimaryButton
                                  type="submit"
                                  title={
                                    buttonLoading ? <ButtonLoading /> : "Submit"
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-1"></div>
                      </div>
                    </Form>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </>
      )}

      <Modal
        centered
        show={successModal}
        onHide={onSuccessModal}
        className="add-department-modal-main"
        // selected={selectedProject}
      >
        <Modal.Body className="success-modal-content-main">
          <div className="text-center">
            <div>
              <img src={TickSquare} alt="check icon" />
            </div>

            <div className="mt-2">
              <p className="success-modal-content-para-main">
                New Project Added
              </p>
              <div className="d-flex gap-3 align-items-center justify-content-center">
                <Button
                  onClick={onSuccessModal}
                  className="primary-bordered-icon-btn"
                >
                  <span className="me-2">
                    <img src={CloseGreyIcon} alt="back icon" />
                  </span>
                  <span>Cancel</span>
                </Button>
                <Button
                  onClick={onSuccessModal}
                  className="primary-theme-icon-btn"
                >
                  <span>Continue</span>
                  <span className="ms-2">
                    <img src={NextWhiteIcon} alt="next icon" />
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
