import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import PermissionFunctions from "../../../assets/functions/PermissionFunctions";
import { useNavigate } from "react-router-dom";
import options from "../../../assets/images/icons/export1.svg";
import axios from "axios";
import MainServices from "../../../services/MainServices";
import useConfig from "../../../hooks/useConfig";
import './ProjectDropdown.scss'
import { notifySuccess, notifyWarn } from "../../../assets/elements/Toast";
import { ButtonLoader } from "../../../assets/loaders";

export default function ProjectExportsDropdown({ projectId, page, projectType }) {
  const { grantPermission } = PermissionFunctions();
  const navigate = useNavigate();
  const { downloadProgressReport, exportProjectCTR, engineeringProgressOverallProgressReport } = MainServices();
  const config = useConfig();

  const [progressLoading, setProgressLoading] = useState(false);

  const handleProgressReportDownloadClick = async () => {
    setProgressLoading(true);
    const details = {
      projectId,
    };

    await axios
      .all([downloadProgressReport(details)])
      .then((response) => {
        if (response.length > 0) {
          const data = response[0].data;
          const fileName = data.response["fileName"];
          if (fileName) {
            const path = `${config.exportsUrl}engineer/${fileName}`;
            downloadProgressFile(path, "progress_report");
          } else {
            setProgressLoading(false);
            notifyWarn("File not found.");
            console.error("File path not found in response.");
          }
        } else {
          setProgressLoading(false);
          notifyWarn("File not found.");
          console.error("File path not found in response.");
        }
      })
      .catch((error) => {
        setProgressLoading(false);
        console.error("Error fetching file:", error);
      });
  };

  const handleProjectSummaryDownloadClick = async () => {
    setProgressLoading(true);
    const details = {
      projectId,
    };
    await axios
      .all([exportProjectCTR(details)])
      .then((response) => {
        if (response.length > 0) {
          const {
            file: { filePath },
          } = response[0].data;
          if (filePath) {
            downloadProgressFile(filePath, "project_summary");
            setProgressLoading(false);
          } else {
            console.error("File path not found in response.");
            setProgressLoading(false);
          }
        } else {
          console.error("File path not found in response.");
          setProgressLoading(false);
        }
      })
      .catch((error) => {
        setProgressLoading(false);
        console.error("Error fetching file:", error);
      });
  };

  const handleOverallProgressReportDownloadClick = async () => {
    setProgressLoading(true);
    const details = {};
    await axios
      .all([engineeringProgressOverallProgressReport(details, projectId)])
      .then((response) => {
        if (response.length > 0) {
          const { fileName } = response[0]?.data?.response;
          if (fileName) {
            const path = `${config.exportsUrl}engineer/${fileName}`;
            downloadProgressFile(path, "overall_progress_report");
            setProgressLoading(false);
          } else {
            console.error("File path not found in response.");
            setProgressLoading(false);
          }
        } else {
          console.error("File path not found in response.");
          setProgressLoading(false);
        }
      })
      .catch((error) => {
        setProgressLoading(false);
        console.error("Error fetching file:", error);
      });
  };

  const downloadProgressFile = (filePath, fileTitle) => {
    const link = document.createElement("a");
    link.href = filePath;
    link.setAttribute("download", `${fileTitle}.xlsx`); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Remove the <a> tag after download
    // notifySuccess("File downloaded successfully");
    setProgressLoading(false);
  };

  return (
    <Dropdown className="trainer-type-dropdown-main project-dropdown">
      <Dropdown.Toggle className="trainer-type-dropdown">
        <span>
          {progressLoading ? 
            <ButtonLoader />
            : (<img
              src={options}
              title="Project Exports"
              style={{ width: "25px", height: "25px" }}
            />)}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {grantPermission("Project_read") && (
          <Dropdown.Item onClick={handleProgressReportDownloadClick}>
            <span title="Download File">Progress Report</span>
          </Dropdown.Item>
        )}
        {grantPermission("Project_read") && (
          <Dropdown.Item 
            onClick={handleProjectSummaryDownloadClick}
          >
            <span title="Download File">Project Summary</span>
          </Dropdown.Item>
        )}
        {grantPermission("Project_read") && (
          <Dropdown.Item 
            onClick={handleOverallProgressReportDownloadClick}
          >
            <span title="Download File">Overall Progress Report</span>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
