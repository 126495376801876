import useAuth from "../../hooks/useAuth";
import usePermission from "../../hooks/usePermission";

export default function PermissionFunctions() {
  const { permissions } = usePermission();
  const { user } = useAuth();
  const allowedRoles = ["admin", "superadmin"];
  const functions = {
    grantPermission: (required_permission) => {
      if (allowedRoles.includes(user.role)) {
        return true;
      }
      return permissions.includes(required_permission);
    },
  };
  return functions;
}
